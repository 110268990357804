var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["small-box", "bg-" + _vm.variant] },
    [
      _c("div", { staticClass: "inner" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "icon" },
        [_c("a-lte-icon", { attrs: { icon: _vm.icon } })],
        1
      ),
      _vm._v(" "),
      _vm.to
        ? _c(
            "router-link",
            { staticClass: "small-box-footer", attrs: { to: _vm.to } },
            [
              _vm._v("\n    " + _vm._s(_vm.footer) + "\n    "),
              _vm._t("footer")
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "small-box-footer" },
            [
              _vm._v("\n    " + _vm._s(_vm.footer) + "\n    "),
              _vm._t("footer")
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }