<template>
  <div class="description-block">
    <h5 class="description-header">{{ header }}</h5>
    <span v-if="text" class="description-text">{{ text }}</span>
    <span v-else><slot /></span>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
