var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "sm-12" },
        [
          _c(
            "q-table",
            {
              ref: "table",
              attrs: {
                loading: _vm.loading,
                error: _vm.error,
                items: _vm.items,
                fields: _vm.fields,
                "new-item": _vm.newItem,
                "row-clickable": false
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(description)",
                  fn: function(row) {
                    return [
                      row.item.description && !row.item._edit
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.item.description) +
                                "\n        "
                            )
                          ])
                        : row.item._edit
                          ? _c("b-form-input", {
                              staticClass: "text-center",
                              attrs: { size: "sm" },
                              model: {
                                value: row.item.description,
                                callback: function($$v) {
                                  _vm.$set(row.item, "description", $$v)
                                },
                                expression: "row.item.description"
                              }
                            })
                          : _c("i", [_vm._v(_vm._s(_vm.$t("empty")))])
                    ]
                  }
                },
                {
                  key: "cell(actions)",
                  fn: function(row) {
                    return [
                      !row.item._edit
                        ? _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.edit"),
                                variant: "info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.table.toggleEdit(row.item)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.confirm"),
                                variant: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.confirmEdit(row.item, row.index)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "check" }
                              })
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            title: _vm.$t("button.delete"),
                            variant: "danger"
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteRP(row.item)
                            }
                          }
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "top-actions" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "success" },
                      on: { click: _vm.createRP }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.create")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }