import axios from 'axios'

function $api(Vue, store) {
  const vm = new Vue()

  const errors = {
    noPermissions(e) {
      return e.response &&
          e.response.status === 403 &&
          e.response.data &&
          e.response.data.message === 'no permissions'
    },
    expiredJwt(e) {
      return e.response && e.response.status === 401
    }
  }

  function createAxios(endpoint = '', auth = false, timeout = 0) {
    const client = axios.create({
      baseURL: process.env.API_URL + endpoint
    })

    if (auth) {
      client.interceptors.request.use(config => {
        if (!store.state.token) {
          throw new Error('Authorization token is required')
        }

        if (timeout > 0) {
          config.timeout = timeout
        }
        config.headers.Authorization = `Bearer ${store.state.token}`
        return config
      })
    }

    client.interceptors.response.use(null, error => {
      console.error(error)

      if (errors.noPermissions(error)) {
        vm.$global.toast('noPermissions')
      } else if (errors.expiredJwt(error)) {
        vm.$global.auth.logout()
      } else {
        vm.$global.toast('error')
      }

      return Promise.reject(error)
    })

    return client
  }

  const api = {
    axios: createAxios(),
    errors,

    login(email, password) {
      return this.axios.post('/login', { email, password })
    }
  }

  api.journal = {
    axios: createAxios('/journal', true),

    get(coll, filter = {}) {
      return this.axios.post(`/${coll}`, filter)
    }
  }

  api.notifs = {
    axios: createAxios('/notifs', true),

    list() {
      return this.axios.get('/list')
    }
  }

  api.users = {
    axios: createAxios('/users', true, 5000),

    me() {
      return this.axios.get('/me')
    },
    get(id) {
      return this.axios.get(`/${id}`)
    },
    list() {
      return this.axios.get('/list')
    },
    create(user) {
      return this.axios.post('/create', user)
    },
    update(user) {
      return this.axios.put(`/${user.id}`, user)
    },
    uploadAvatar(id, data) {
      return this.axios.post(`/${id}/avatar`, data)
    },
    ban(id) {
      return this.axios.post(`/${id}/ban`, id)
    },
    unban(id) {
      return this.axios.post(`/${id}/unban`, id)
    },
    delete(id) {
      return this.axios.delete(`/${id}`, id)
    }
  }

  api.permissions = {
    axios: createAxios('/permissions', true),

    list() {
      return this.axios.get('/list')
    }
  }

  api.groups = {
    axios: createAxios('/groups', true),

    create(group) {
      return this.axios.post('/create', group)
    },
    list() {
      return this.axios.get('/list')
    },
    update(group) {
      return this.axios.put(`/${group.id}`, group)
    },
    delete(id) {
      return this.axios.delete(`/${id}`, id)
    }
  }

  api.certs = {
    axios: createAxios('/certs', true),

    list(offset, limit) {
      return this.axios.get(`/list?offset=${offset}&limit=${limit}`)
    },
    get(ids) {
      return this.axios.get(`/get?ids=${ids.join(',')}`)
    },
    instock(ids) {
      return this.axios.post(`/instock?ids=${ids.join(',')}`)
    },
    transfer(ids, transferTo, notifId = 0) {
      return this.axios.post(
        `/transfer?ids=${ids.join(',')}`,
        { transferTo, notifId }
      )
    },
    sell(ids, soldAt = null) {
      return this.axios.post(`/sell?ids=${ids.join(',')}`, { soldAt })
    },
    activate(ids, activatedAt = null) {
      return this.axios.post(`/activate?ids=${ids.join(',')}`, { activatedAt })
    },
    secret(id) {
      return this.axios.get(`/${id}/secret`)
    },
    extend(id, expiresAt) {
      return this.axios.post(`/${id}/extend`, { expiresAt })
    },
    ban(id) {
      return this.axios.post(`/${id}/ban`)
    },
    groups() {
      return this.axios.get('/groups/list')
    },
    createGroup(group) {
      return this.axios.post('/groups/create', group)
    },
    banGroup(groupId) {
      return this.axios.post(`/groups/${groupId}/ban`)
    },
    instockGroup(groupId) {
      return this.axios.post(`/groups/${groupId}/instock`)
    },
    exportGroup(groupId) {
      return this.axios.get(`/groups/${groupId}/export`)
    }
  }

  api.stocks = {
    axios: createAxios('/stocks', true, 5000),

    list() {
      return this.axios.get('/list')
    },
    create(stock) {
      return this.axios.post('/create', stock)
    },
    update(stock) {
      return this.axios.put(`/${stock.id}`, stock)
    },
    delete(id) {
      return this.axios.delete(`/${id}`)
    },
    listInventory(offset, limit, type, query = '') {
      return this.axios.get(`/inventory/list?offset=${offset}&limit=${limit}&type=${type}&query=${query}`)
    },
    searchInventory(type, query) {
      return this.axios.get(`/inventory/search?type=${type}&query=${query}`)
    },
    listInventoryStocks(id) {
      return this.axios.get(`/inventory/${id}/stocks`, id)
    },
    createInventory(inventory) {
      return this.axios.post('/inventory/create', inventory)
    },
    updateInventory(inventory) {
      return this.axios.put(`/inventory/${inventory.id}`, inventory)
    },
    deleteInventory(id) {
      return this.axios.delete(`/inventory/${id}`)
    },
    uploadInventoryPhoto(id, data) {
      return this.axios.post(`/inventory/${id}/photo`, data)
    },
    deleteInventoryPhoto(id, photo) {
      return this.axios.delete(`/inventory/${id}/photo?hashext=${photo}`)
    }
  }

  api.vehicles = {
    axios: createAxios('/vehicles', true, 5000),

    get(id) {
      return this.axios.get(`/${id}`)
    },
    list() {
      return this.axios.get('/list')
    },
    idle() {
      return this.axios.get('/idle')
    },
    create(veh) {
      return this.axios.post('/create', veh)
    },
    update(veh) {
      return this.axios.put(`/${veh.id}`, veh)
    },
    updateState(id, state) {
      return this.axios.put(`${id}/state`, { state })
    },
    delete(id) {
      return this.axios.delete(`/${id}`)
    },
    uploadPhoto(id, data) {
      return this.axios.post(`/${id}/photo`, data)
    },
    deletePhoto(id, photo) {
      return this.axios.delete(`/${id}/photo?hashext=${photo}`)
    }

  }


  api.rentalPoints = {
    axios: createAxios('/rentalPoints', true),

    list() {
      return this.axios.get('/list')
    },
    create(rp) {
      return this.axios.post('/create', rp)
    },
    update(rp) {
      return this.axios.put(`/${rp.id}`, rp)
    },
    delete(id) {
      return this.axios.delete(`/${id}`)
    }
  }

  api.services = {
    axios: createAxios('/services', true),

    list() {
      return this.axios.get('/list')
    },
    create(service) {
      return this.axios.post('/create', service)
    },
    update(service) {
      return this.axios.put(`/${service.id}`, service)
    },
    delete(id) {
      return this.axios.delete(`/${id}`)
    }
  }


  api.times = {
    axios: createAxios('/services/times', true),

    list() {
      return this.axios.get('/list')
    },

    create(entry) {
      return this.axios.post('/add', entry)
    },

    delete(id) {
      return this.axios.delete(`/${id}`)
    }
  }

  api.schedule = {
    axios: createAxios('/schedule', true),

    list() {
      return this.axios.get('/list')
    },


    create(scheduleEntry) {
      return this.axios.post('/create', scheduleEntry)
    },

    update(scheduleEntry) {
      return this.axios.put(`/${scheduleEntry.id}`, scheduleEntry)
    },

    delete(id) {
      return this.axios.delete(`/${id}`)
    },

    uploadPhoto(id, data) {
      return this.axios.post(`/${id}/photo`, data)
    }
  }

  api.bot = {
    axios: createAxios('/bot', true),

    sendSchedule(date) {
      return this.axios.get(`/sendSchedule?date=${date}`)
    }
  }

  return api
}

export default {
  install(Vue, store) {
    Vue.prototype.$api = $api(Vue, store)
  }
}
