<template>
  <div>
    <b-row v-if="control" class="mb-2 justify-content-center">
      <b-col sm="3">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="items.length"
          align="fill"
          size="sm"
          class="my-0"
        />
      </b-col>
      <b-col sm="1">
        <b-form-input
          v-model="perPage"
          type="number"
          size="sm"
          min="10"
          max="50"
        />
      </b-col>
      <b-col sm="2" class="mb-2">
        <b-form-select
          v-model="byModule"
          :options="modulesOptions"
          label="Модуль"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-card class="sm-12">
      <b-table
        ref="table"
        :busy="loading"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="byModule"
        :filter-included-fields="['module', 'type']"
        :sort-desc="true"
        sort-by="createdAt"
        head-variant="light"
        hover
        small
        outlined
        bordered
        class="text-center"
        responsive="sm"
        @row-clicked="item => rowClicked(item) /* item=>$set(item, '_showDetails', !item._showDetails) */"
      >
        <template v-slot:table-busy>
          <div v-if="loading" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Загрузка...</strong>
          </div>
          <div v-else-if="error" class="text-center text-danger my-2">
            <strong>Ошибка!...</strong>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Boolean,
      default: true
    },
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      currentPage: 1,
      perPage: 15,
      items: [],

      byModule: null,
      modulesOptions: [
        null,
        'none',
        'users',
        'groups',
        'certGroups',
        'stocks',
        'inventory',
        'vehicles',
        'services',
        'rentalPoints',
        'schedule'
      ].map(key => ({
        value: key,
        text: this.$t(`module.${key}`)
      })),

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: (v) => new Date(v).toLocaleString()
        },
        {
          key: 'module',
          label: this.$t('field.module'),
          sortable: true,
          formatter: v => this.$t(`module.${v}`)
        },
        {
          key: 'type',
          label: this.$t('field.type'),
          sortable: true,
          formatter: v => this.$t(`type.${v}`)
        },
        {
          key: '_id',
          label: this.$t('field._id'),
          formatter: this.formatRecord
        }
      ]
    }
  },
  async mounted() {
    if (!this.$global.hasPermissions('journal.read')) {
      return
    }

    try {
      let filter
      if (this.userId > 0) {
        // manually allow necessary records, using mongo syntax to query data
        // remove type column from fields as well
        this.fields = this.fields.filter(f => f.key !== 'type')

        filter = {
          $or: [
            {
              ownerId: this.userId
            },
            {
              certs: {
                $elemMatch: {
                  $or: [
                    { 'owner.id': this.userId },
                    { 'transferTo.id': this.userId },
                    { 'soldBy.id': this.userId },
                    { 'activatedBy.id': this.userId }
                  ]
                }
              }
            },
            {
              certGroup: {
                'creator.id': this.userId
              }
            }
          ]
        }
      }

      const { data } = await this.$api.journal.get('syslog', filter)
      this.items = data
      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }
  },
  methods: {
    rowClicked(item) {
      console.log(item)
    },
    formatRecord(id) {
      const item = Object.assign({}, this.items.find(v => v._id === id))

      switch (item.module) {
        case 'none':
          if (item.browser) {
            item.type += 'Browser'
          }
          break
        case 'certs':
          item.range = this.$global.rangeString(item.certs.map(v => v.id))
          item.owner = item.certs[0].owner
          item.transferTo = item.certs[0].transferTo

          if (item.backdating && !this.compareDates(item.createdAt, item.backdating)) {
            item.type += 'Backdating'
            item.backdating = new Date(item.backdating).toLocaleDateString()
          }

          break
        case 'inventory':
          item.inventoryItem.typeString = this.$t(`record.inventory.typeString.${item.inventoryItem.type}`)
          break
        case 'vehicles':
          item.vehicle.stateString = this.$t(`record.vehicles.stateString.${item.vehicle.state}`)
          break
        default:
      }

      return this.$i18n.tf(`record.${item.module}.${item.type}`, item)
    },
    compareDates(a, b) {
      const ta = new Date(a)
      const tb = new Date(b)
      ta.setHours(0, 0, 0, 0)
      tb.setHours(0, 0, 0, 0)
      return ta.getTime() === tb.getTime()
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
field:
  createdAt: Дата
  module: Модуль
  type: Тип
  _id: Запись
module:
  null: "Все"
  none: "Система"
  users: "Пользователи"
  groups: "Группы"
  certs: "Сертификаты: Просмотр"
  certGroups: "Сертификаты: Группы"
  stocks: "Склады"
  inventory: "Инвентарь"
  vehicles: "Техника"
  services: "Услуги"
  rentalPoints: "Точка проката"
  scheduleEntries: "Календарь"
type:
  login: Авторизация
  create: Создание
  edit: Изменение
  delete: Удаление
  instock: На склад
  extend: Продление
  ban: Блокировка
  transfer: Передача
  accept: Принятие/В работу
  sell: Продажа
  activate: Активация
  state: Состояние
record:
  none:
    login: "Пользователь {user.fullName} вошел в систему ({ip}, {device})"
    loginBrowser: "Пользователь {user.fullName} вошел в систему ({ip}, {device}, {browser})"
  users:
    create: "Создан пользователь {user.fullName}, {user.email}"
    edit: "Отредактирован пользователь {user.fullName}, {user.email}"
    delete: "Удалён пользователь {user.fullName}, {user.email}"
  groups:
    create: "Создана группа {group.name}"
    edit: "Отредактирована группа {group.name}"
    delete: "Удалена группа {group.name}"
  certs:
    instock: "Возвращены на склад сертификаты {range}"
    transfer: "Переданы сертификаты {range} от {owner.fullName} к {transferTo.fullName}"
    sell: "Проданы сертификаты {range}"
    activate: "Активированы сертификаты {range}"
    sellBackdating: "Проданы сертификаты {range} задним числом: {backdating}"
    activateBackdating: "Активированы сертификаты {range} задним числом: {backdating}"
    accept: "Запущены в работу сертификаты {range} пользователем {transferTo.fullName}"
    ban: "Заблокирован сертификат {range}"
  certGroups:
    create: "Создана группа сертификатов {certGroup.startId}..{certGroup.endId}"
    extend: "Продлена группа сертификатов {certGroup.startId}..{certGroup.endId}"
    ban: "Заблокирована группа сертификатов {certGroup.startId}..{certGroup.endId}"
    instock: "Возвращена на склад группа сертификатов {certGroup.startId}..{certGroup.endId}"
    transfer: "Передана группа сертификатов {certGroup.startId}..{certGroup.endId} от {certGroup.owner.fullName} к {certGroup.transferTo.fullName}"
    accept: "Запущена в работу группа сертификатов {certGroup.startId}..{certGroup.endId} пользователем {certGroup.transferTo.fullName}"
  stocks:
    create: "Создана склад {stock.name}"
    edit: "Отредактирован склад {stock.name}"
    delete: "Удалён склад {stock.name}"
  inventory:
    create: "Создан{inventoryItem.typeString} {inventoryItem.name}"
    edit: "Отредактирован{inventoryItem.typeString} {inventoryItem.name}"
    delete: "Удалён{inventoryItem.typeString} {inventoryItem.name}"

    typeString:
      inventory: " предмет"
      consumable: " расходник"
      spare: "а запчасть"
  vehicles:
    create: "Добавлена техника {vehicle.name}"
    edit: "Отредактирована техника {vehicle.name}"
    delete: "Удалена техника {vehicle.name}"
    state: "Изменено состояние {vehicle.name} на \"{vehicle.stateString}\""

    stateString:
      idle: в гараже
      rental: в прокате
      repair: в ремонте
      run-in: в обкатке
  services:
    create: "Создана услуга {service.name}"
    edit: "Отредактирована услуга {service.name}"
    delete: "Удалена услуга {service.name}"
  rentalPoints:
    create: "Создана точка проката {rentalPoint.name}"
    edit: "Отредактирована точка проката {rentalPoint.name}"
    delete: "Удалена точка проката {rentalPoint.name}"
  scheduleEntries:
    create: "Создана запись в календаре, клиент — {scheduleEntry.customer}"
    edit: "Отредактирована запись в календаре, клиент — {scheduleEntry.customer}"
    delete: "Удалена запись в календаре, клиент — {scheduleEntry.customer}"
</i18n>
