var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "footer",
    _vm._g(
      _vm._b({ staticClass: "main-footer" }, "footer", _vm.data.attrs, false),
      _vm.listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }