var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: { "nav-item": true, "menu-open": _vm.open } },
    [
      _c(
        "a",
        {
          class: { "nav-link": true, active: _vm.active },
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggle($event)
            }
          }
        },
        [
          _c("a-lte-icon", {
            staticClass: "nav-icon",
            attrs: { icon: _vm.icon }
          }),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
              _c("a-lte-icon", {
                staticClass: "right",
                attrs: { icon: _vm.iconExpand }
              }),
              _vm._v(" "),
              _vm.badge
                ? _c(
                    "span",
                    { class: ["right", "badge", "badge-" + _vm.badgeVariant] },
                    [_vm._v(_vm._s(_vm.badge))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "aside-dropdown" },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            leave: _vm.leave
          }
        },
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open"
                }
              ],
              ref: "dropdown",
              staticClass: "nav nav-treeview"
            },
            [_vm._t("default")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }