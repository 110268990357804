<template>
  <b-table
    ref="table"
    :busy="loading"
    :items="items"
    :fields="fields"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :tbody-tr-class="rowClass"
    head-variant="light"
    bordered
    hover
    responsive="sm"
    class="text-center"
    @row-clicked="toggleDetails"
  >
    <template v-slot:table-busy>
      <div v-if="loading" class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong >{{ $t('loading') }}</strong>
      </div>
      <div v-else-if="error" class="text-center text-danger my-2">
        <strong >{{ $t('error') }}</strong>
      </div>
    </template>

    <template
      v-if="topRow"
      slot="top-row"
      slot-scope="{ fields }"
    >
      <b-td v-for="(field, key) in fields" :key="key">
        <template v-if="field.editable || field.editableTop">
          <b-col>
            <b-form-select
              v-if="field.selectOptions"
              v-model="newItem[field.key]"
              :options="field.selectOptions()"
              size="sm"
            ></b-form-select>
            <b-form-datepicker
              v-else-if="field.isDate"
              v-model="newItem[field.key]"
              :state="field.validator(newItem)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="new Date()"
              size="sm"
              placeholder="Дата"
              label-help=""
            ></b-form-datepicker>
            <b-form-input
              v-else
              v-model="newItem[field.key]"
              :state="field.validator(newItem)"
              :type="field.input.type || 'text'"
              :min="field.input.min"
              :max="field.input.max"
              class="text-center"
              size="sm"
              @focus="field._focus = true"
              @blur="field._focus = !!newItem[field.key]"
            ></b-form-input>
          </b-col>
        </template>
        <b-col v-else-if="field.key === 'actions'">
          <slot name="top-actions" />
        </b-col>
        <span v-else>
          {{ field.formatter(newItem[field.key]) }}
        </span>
      </b-td>
    </template>

    <template v-slot:cell()="row">
      <span v-if="!row.item._edit || !row.field.editable">
        {{ row.value }}
      </span>
      <b-col v-else>
        <b-form-select
          v-if="row.field.selectOptions"
          v-model="row.item[row.field.key]"
          :options="row.field.selectOptions()"
          :disabled="row.field.disabled(row.item)"
          size="sm"
        ></b-form-select>
        <b-form-datepicker
          v-else-if="row.field.isDate"
          v-model="row.item[row.field.key]"
          :state="row.field.validator(row.item)"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :min="new Date()"
          size="sm"
          placeholder="Дата"
          label-help=""
        ></b-form-datepicker>
        <b-form-input
          v-else
          v-model="row.item[row.field.key]"
          :state="row.field.validator(row.item)"
          :min="row.field.input.min"
          :max="row.field.input.max"
          class="text-center"
          size="sm"
        ></b-form-input>
      </b-col>
    </template>

    <template v-slot:[slot]="row" v-for="slot in cellSlots" >
      <slot :name="slot" v-bind="row" />
    </template>

    <template slot="row-details" slot-scope="row">
      <slot v-bind="row" name="row-details" />
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    rowClickable: {
      type: Boolean,
      default: true
    },
    topRow: {
      type: Boolean,
      default: true
    },
    rowClass: {
      type: Function,
      default: () => ''
    },
    newItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      cachedItem: {},
      sortBy: null,
      sortDesc: null
    }
  },
  computed: {
    cellSlots() {
      const slots = []
      Object.keys(this.$scopedSlots).forEach(slot => {
        if (slot.startsWith('cell')) slots.push(slot)
      })
      return slots
    }
  },
  created() {
    this.normalizeFields()
  },
  methods: {
    normalizeFields() {
      this.fields.forEach(field => {
        if (!field.input) {
          field.input = {
            min: undefined,
            max: undefined
          }
        }
        if (!field.disabled) {
          field.disabled = () => false
        }
        if (!field.formatter) {
          field.formatter = v => (v || '')
        }

        // validator works only with specific field,
        // but component passes item as an argument
        const { validator } = field
        field.validator = validator
          ? item => validator(item[field.key])
          : () => undefined
      })
    },

    isFieldsValid(item) {
      return this.fields
        .filter(f => f.validator(item) !== undefined)
        .every(f => f.validator(item))
    },
    isItemsEqual(...items) { // must be 2 items
      const copies = []
      for (let i = 0; i < items.length; i++) {
        const copy = Object.assign({}, items[i])
        Object.keys(copy).forEach(k => { if (k[0] === '_') delete copy[k] })
        copies[i] = copy
      }
      return JSON.stringify(copies[0]) === JSON.stringify(copies[1])
    },
    isItemCached(item) {
      return this.isItemsEqual(item, this.cachedItem)
    },

    toggleDetails(item) {
      if (!this.rowClickable) return
      this.$emit('toggle-details', item)
      this.$set(item, '_showDetails', !item._showDetails)
    },
    toggleEdit(item) {
      this.$emit('toggle-edit', item)
      this.cachedItem = JSON.parse(JSON.stringify(item))
      this.$set(item, '_edit', !item._edit)
    },
    restoreItem(index) {
      this.$set(this.items, index, JSON.parse(JSON.stringify(this.cachedItem)))
    },

    refresh() {
      this.$refs.table.refresh()
    },
    sort(by, desc = null) {
      this.sortBy = by
      if (desc !== null) {
        this.sortDesc = desc
      }
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
loading: Загрузка...
error: Ошибка!...
</i18n>
