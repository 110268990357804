var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-panel mt-3 pb-3 mb-3 d-flex" }, [
    _c("div", { staticClass: "image" }, [
      _c("img", {
        class: ["img-circle", _vm.elevationClass],
        attrs: {
          src: _vm.image,
          srcset: (_vm.image2x || _vm.image) + " 2x",
          alt: _vm.username
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("router-link", { attrs: { to: _vm.to } }, [
          _vm._v(_vm._s(_vm.username))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }