import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations'

export default [
  createPersistedState(),
  sharedMutations({
    predicate: [
      'setUser',
      'setToken'
    ]
  })
]
