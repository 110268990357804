<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
      >
        <template slot="top-actions">
          <b-btn
            variant="success"
            @click="openModalCreate"
          >
            <font-awesome-icon icon="plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-row>
            <b-col
              v-for="(value, key) in row.item.stats"
              :key="key"
              sm4
            >
              <a-lte-description-block
                :class="(key === 'banned' && value === row.item.count) ? 'text-danger' : ''"
                :header="$t(`stats.${key}`)"
              >
                <span>{{ value }}</span>
              </a-lte-description-block>
            </b-col>
          </b-row>
        </template>

        <template v-slot:cell(stats)="row">
          <span style="white-space: pre;">{{ row.value }}</span>
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.export')"
            :disabled="!$global.hasPermissions('certs.readSecret')"
            variant="info"
            @click="exportCertGroup(row.item)"
          >
            <font-awesome-icon icon="download" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-if="row.item.stats.banned < row.item.count"
            :title="$t('button.ban')"
            :disabled="isDisabled(row.item, true)"
            variant="danger"
            @click="banCertGroup(row.item, true)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.unban')"
            :disabled="isDisabled(row.item, true) || isExpired(row.item)"
            variant="success"
            @click="banCertGroup(row.item, false)"
          >
            <font-awesome-icon icon="unlock" />
          </b-btn>
        </template>
      </q-table>
    </b-card>

    <b-modal
      id="modal-create"
      v-model="modalCreate.show"
      :title="$t('modal.create.title')"
      :ok-title="$t('modal.create.ok')"
      :cancel-title="$t('modal.create.cancel')"
      @ok="createItem"
      @hidden="resetModalCreate"
    >
      {{ $t('modal.create.message') }}
      <b-datepicker
        v-model="modalCreate.expiresAt"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :min="new Date()"
        size="sm"
        placeholder="Дата"
        label-help=""
      />
    </b-modal>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],
      newItem: {},

      modalCreate: {
        show: false,
        expiresAt: null
      },

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleDateString()
        },
        {
          key: 'range',
          label: this.$t('field.range'),
          sortable: true,
          formatter: v => `${v[0]} .. ${v[1] || ''}`
        },
        {
          key: 'count',
          label: this.$t('field.count'),
          sortable: true,
          editableTop: true,
          validator: v => v > 0 && v <= 1000,

          input: {
            min: 0,
            max: 1000
          }
        },
        {
          key: 'cost',
          label: this.$t('field.cost'),
          sortable: true,
          editableTop: true,
          validator: v => v >= 0,
          input: { min: 0 }
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  computed: {
    newItemCount() {
      return this.newItem.count
    }
  },
  watch: {
    newItemCount(count) {
      this.newItem.range[1] = this.newItem.range[0] + parseInt(count, 10)
    }
  },
  async mounted() {
    try {
      const { data: { certGroups } } = await this.$api.certs.groups()
      this.items = (certGroups || []).map(v => this.normalizeItem(v))

      this.newItem = this.defaultNewItem()
      this.loading = false
    } catch (e) {
      this.error = true
    }
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row' && this.isExpired(item)) {
        return 'table-warning'
      }
      return ''
    },

    isDisabled(item, checkBan = false) {
      // only super admin can ban cert
      // only creator can manage cert
      // certs.write permissions are required
      // actions should be disabled if group is banned

      return (!this.$global.isSuperAdmin() && (checkBan
        || item.creator.id !== this.$store.state.user.id))
        || !this.$global.hasPermissions('certs.write')
        || (!checkBan && item.stats.banned === item.count)
    },

    defaultNewItem() {
      const maxId = Math.max(...this.items.map(v => v.endId))
      const item = { range: [(maxId > 0 ? maxId : 1), undefined] }
      return this.normalizeItem(item)
    },
    normalizeItem(item) {
      if (!item.createdAt) {
        item.createdAt = new Date()
      }
      if (!item.stats) {
        item.stats = {}
      }
      if (item.startId && item.endId) {
        item.count = (item.endId - item.startId) + 1
        item.range = [item.startId, item.endId]
      }
      return item
    },

    openModalCreate() {
      if (!this.$refs.table.isFieldsValid(this.newItem)) {
        this.$global.toast('dataInvalid')
        return
      }
      this.$set(this.modalCreate, 'show', true)
    },
    resetModalCreate() {
      this.modalCreate.expiresAt = null
    },

    async createItem() {
      try {
        // validating all fields
        this.newItem.expiresAt = new Date(this.modalCreate.expiresAt)
        this.newItem.count = parseInt(this.newItem.count, 10)
        this.newItem.cost = parseInt(this.newItem.cost, 10)

        this.loading = true
        const { data } = await this.$api.certs.createGroup(this.newItem)

        const item = this.normalizeItem(data.certGroup)
        item._showDetails = true

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('certs.created', 'successInfo')
        this.$refs.table.sort('range', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    banCertGroup(item, ban) {
      this.$bvModal.msgBoxConfirm(this.$t(`modal.ban.message.${ban}`), {
        title: this.$t('modal.ban.title'),
        okTitle: this.$t(`modal.ban.ok.${ban}`),
        cancelTitle: this.$t('modal.ban.cancel'),
        okVariant: ban ? 'danger' : 'success',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          this.loading = true

          if (ban) {
            await this.$api.certs.banGroup(item.id)
            Object.keys(item.stats).forEach(key => { item.stats[key] = 0 })
            item.stats.banned = item.count
            item.transferTo = null
          } else {
            await this.$api.certs.instockGroup(item.id)
            item.stats.banned = 0
            item.stats.instock = item.count
            item.transferTo = null
          }
        } catch (e) {
          this.error = true
        } finally {
          this.loading = false
          this.$refs.table.refresh()
        }
      })
    },
    async exportCertGroup(item) {
      try {
        const { data } = await this.$api.certs.exportGroup(item.id)
        const fileType = 'text/csv'

        const a = document.createElement('a')
        a.download = `certGroup_${item.startId}-${item.endId}.csv`
        a.href = URL.createObjectURL(new Blob([data], { type: fileType }))
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.setTimeout(() => { URL.revokeObjectURL(a.href) }, 1500)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  create: Сгенерировать
  ban: Заблокировать
  unban: Разблокировать
  export: Экспорт
field:
  createdAt: Создан
  expiresAt: Дата истечения
  range: Диапазон
  count: Количество
  cost: Номинал, грн
  actions: Действия
stats:
  instock: На складе
  ready: Готов к активации
  sold: Продано
  activated: Активировано
  banned: Заблокировано
modal:
  create:
    message: "Установите дату истечения жизни сертификатов:"
    title: Дата истечения
    ok: Создать
    cancel: Отмена
</i18n>
