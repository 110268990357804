var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-widget widget-user" },
    [
      _c("div", { class: ["widget-user-header", "bg-" + _vm.color] }, [
        _c("h3", { staticClass: "widget-user-username" }, [
          _vm._v(_vm._s(_vm.username))
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "widget-user-desc" }, [
          _vm._v(_vm._s(_vm.desc))
        ])
      ]),
      _vm._v(" "),
      _c("b-file", {
        ref: "file",
        staticClass: "position-absolute invisible",
        attrs: { accept: ".png, .jpg" },
        on: { input: _vm.input }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "widget-user-image" },
        [
          _c("img", {
            class: ["img-circle", _vm.elevationClass],
            attrs: { src: _vm.avatar }
          }),
          _vm._v(" "),
          _vm.input
            ? _c(
                "b-button",
                {
                  staticClass: "mb-4",
                  attrs: { variant: _vm.color, pill: true },
                  on: { click: _vm.click }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "camera", size: "lg" }
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: { icon: "plus", size: "xs" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }