var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "sm-12" },
        [
          _c(
            "q-table",
            {
              ref: "table",
              attrs: {
                loading: _vm.loading,
                error: _vm.error,
                items: _vm.items,
                fields: _vm.fields,
                "new-item": _vm.newItem
              },
              on: { "toggle-details": _vm.itemClicked },
              scopedSlots: _vm._u([
                {
                  key: "cell(photo)",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "position-relative" },
                        [
                          !_vm.photosEmpty(row.item)
                            ? _c(
                                "viewer",
                                {
                                  attrs: {
                                    trigger: row.item.photos,
                                    rebuild: ""
                                  },
                                  on: { inited: _vm.setViewer }
                                },
                                _vm._l(row.item.photos, function(src, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: src,
                                      staticClass: "position-absolute",
                                      staticStyle: { left: "50%" }
                                    },
                                    [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          },
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: i === 0,
                                            expression: "i === 0"
                                          }
                                        ],
                                        staticClass:
                                          "photo-field position-relative",
                                        attrs: {
                                          src: _vm.$global.storagePath(
                                            "inventory",
                                            row.item.id,
                                            src
                                          ),
                                          title:
                                            row.item.photos.length > 1
                                              ? "еще " +
                                                (row.item.photos.length - 1) +
                                                " фото"
                                              : undefined
                                        },
                                        on: {
                                          mouseover: function($event) {
                                            _vm.overImageViewer = true
                                          },
                                          mouseleave: function($event) {
                                            _vm.overImageViewer = false
                                          }
                                        }
                                      })
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              class: !_vm.photosEmpty(row.item)
                                ? ["position-absolute", "mt-3", "ml-2"]
                                : undefined,
                              attrs: {
                                pill: true,
                                size: !_vm.photosEmpty(row.item) ? "xs" : "sm"
                              },
                              on: {
                                click: function($event) {
                                  _vm.$refs["file-" + row.item.id].$el
                                    .querySelector("input[type=file]")
                                    .click()
                                }
                              }
                            },
                            [
                              _vm.photosEmpty(row.item)
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: "camera" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("font-awesome-icon", {
                                attrs: { icon: "plus", size: "xs" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-file", {
                            ref: "file-" + row.item.id,
                            staticClass: "position-absolute invisible",
                            staticStyle: { width: "0" },
                            attrs: { accept: ".png, .jpg", multiple: "" },
                            on: {
                              input: function(files) {
                                return _vm.uploadPhotos(row.item, files)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "cell(actions)",
                  fn: function(row) {
                    return [
                      !row.item._edit
                        ? _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.edit"),
                                variant: "info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.table.toggleEdit(row.item)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.confirm"),
                                variant: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.confirmEdit(row.item, row.index)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "check" }
                              })
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            title: _vm.$t("button.delete"),
                            variant: "danger"
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteInventory(row.item)
                            }
                          }
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm4: "" } },
                            [
                              _c(
                                "a-lte-description-block",
                                { attrs: { header: _vm.$t("header.owner") } },
                                [
                                  !row.item._edit && row.item.owner
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.item.owner.fullName) +
                                            "\n              "
                                        )
                                      ]
                                    : row.item._edit
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "justify-content-center"
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    options: _vm.usersOptions,
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value: row.item.owner,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row.item,
                                                        "owner",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.item.owner"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("i", [
                                          _vm._v(_vm._s(_vm.$t("empty")))
                                        ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm4: "" } },
                            [
                              _c(
                                "a-lte-description-block",
                                {
                                  attrs: {
                                    header: _vm.$t("header.description")
                                  }
                                },
                                [
                                  row.item.description && !row.item._edit
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "white-space": "pre" }
                                        },
                                        [_vm._v(_vm._s(row.item.description))]
                                      )
                                    : row.item._edit
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "justify-content-center"
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "header.description"
                                                    ),
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value: row.item.description,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row.item,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.item.description"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("i", [
                                          _vm._v(_vm._s(_vm.$t("empty")))
                                        ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "top-actions" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "success" },
                      on: { click: _vm.createInventory }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.create")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }