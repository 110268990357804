var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "nav-item" },
    [
      _c(
        "router-link",
        {
          staticClass: "nav-link",
          attrs: { to: _vm.to, "active-class": "active", exact: "" }
        },
        [
          _c("a-lte-icon", {
            staticClass: "nav-icon",
            attrs: { icon: _vm.icon }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
            _vm.badge
              ? _c(
                  "span",
                  { class: ["right", "badge", "badge-" + _vm.badgeVariant] },
                  [_vm._v(_vm._s(_vm.badge))]
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }