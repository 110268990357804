<template>
  <b-row class="justify-content-center">
    <b-col sm="12" xl="12">
      <a-lte-card-widget-user
        :username="user.fullName"
        :desc="user.group.name"
        :avatar="$global.userAvatar(user)"
        @upload="uploadAvatar"
      >
        <b-row>
          <b-col sm4 class="border-right">
            <a-lte-description-block :header="$t('header.email')">
              {{ user.email }}
            </a-lte-description-block>
          </b-col>
          <b-col sm4>
            <a-lte-description-block :header="$t('header.phone')">
              {{ user.phone }}
            </a-lte-description-block>
          </b-col>
          <b-col sm4 class="border-left">
            <a-lte-description-block :header="$t('header.rentalPoints')">
              <template v-for="rp in user.rentalPoints">
                <span :key="rp.id" style="white-space: pre;">{{ rp.name }}<br/></span>
              </template>
            </a-lte-description-block>
          </b-col>
        </b-row>
      </a-lte-card-widget-user>
    </b-col>
    <b-col v-if="showJournal" cols="12">
      <h2 class="m-0 mb-2 mt-2 text-dark" style="font-size: 1.8rem;">Журнал действий</h2>
      <Journal
        ref="journal"
        :control="false"
        :user-id="$store.state.user.id"
      />
    </b-col>
  </b-row>
</template>

<script>
import Journal from '@/views/Journal.vue'

export default {
  components: {
    Journal
  },
  data() {
    return {
      user: this.$store.state.user,
      avatarFile: null,
      showJournal: true
    }
  },
  async mounted() {
    if (this.$route.query.from === 'login') {
      this.$router.replace({ query: {} })
      return
    }

    try {
      const { data } = await this.$api.users.me()
      if (data.user) {
        this.$store.commit('setUser', data.user)
        this.user = data.user
      }
    } catch (e) {
      this.$global.toast('error')
    }
  },
  updated() {
    this.showJournal =
        this.$global.hasPermissions('journal.read') &&
        this.$refs.journal &&
        this.$refs.journal.items &&
        this.$refs.journal.items.length
  },
  methods: {
    async uploadAvatar(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert(this.$t('error.invalidType')) // eslint-disable-line
        return
      }
      if (file.size > this.$global.maxImageSize) {
        alert(this.$t('error.tooBig')) // eslint-disable-line
        return
      }

      const data = new FormData()
      data.append('file', file)
      await this.$api.users.uploadAvatar(this.user.id, data)
      this.$router.go(0)
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
header:
  email: Email
  phone: Телефон
  rentalPoints: Точки проката
error:
  invalidType: Неверный тип файла!
  tooBig: Максимальный размер загружаемого фото — 64 МБ
</i18n>
