<template>
  <div class="card card-widget widget-user">
    <div :class="['widget-user-header', `bg-${color}`]">
      <h3 class="widget-user-username">{{ username }}</h3>
      <h5 class="widget-user-desc">{{ desc }}</h5>
    </div>
    <b-file
      ref="file"
      class="position-absolute invisible"
      accept=".png, .jpg"
      @input="input"
    />
    <div class="widget-user-image">
      <img :src="avatar" :class="['img-circle', elevationClass]">
      <b-button
        v-if="input"
        :variant="color"
        :pill="true"
        class="mb-4"
        @click="click"
      >
        <font-awesome-icon icon="camera" size="lg" />
        <font-awesome-icon icon="plus" size="xs" />
      </b-button>
    </div>
    <div class="card-footer">
      <slot />
    </div>
  </div>
</template>

<script>
import elevation from '../../mixins/elevation'

export default {
  mixins: [elevation()],
  props: {
    color: {
      type: String,
      default: 'info'
    },
    username: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  },
  methods: {
    click() {
      this.$refs.file.$el.querySelector('input[type=file]').click()
    },
    input(file) {
      this.$emit('upload', file)
    }
  }
}
</script>
