var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "sm-12" },
    [
      _c(
        "q-table",
        {
          ref: "table",
          attrs: {
            loading: _vm.loading,
            error: _vm.error,
            items: _vm.items,
            fields: _vm.fields,
            "new-item": _vm.newItem,
            "row-clickable": false
          },
          on: { "toggle-edit": _vm.toggleEdit },
          scopedSlots: _vm._u([
            {
              key: "cell(actions)",
              fn: function(row) {
                return [
                  !row.item._edit
                    ? _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled: _vm.isDisabled(row.item),
                            variant: "info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.table.toggleEdit(row.item)
                            }
                          }
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                        1
                      )
                    : _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled: _vm.isDisabled(row.item),
                            variant: "success"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmEdit(row.item, row.index)
                            }
                          }
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "check" } })],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: {
                        disabled: _vm.isDisabled(row.item),
                        variant: "danger"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteItem(row.item)
                        }
                      }
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                    1
                  )
                ]
              }
            },
            {
              key: "row-details",
              fn: function(row) {
                return [
                  _c(
                    "div",
                    { staticClass: "text-left" },
                    _vm._l(_vm.permissions, function(permsGroup, key) {
                      return _c(
                        "b-card",
                        {
                          key: key,
                          staticClass: "mt-2",
                          attrs: { "no-body": "" }
                        },
                        [
                          _c(
                            "b-card-header",
                            [
                              _c(
                                "b-card-title",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-checkbox", {
                                        staticClass: "ml-2",
                                        attrs: {
                                          disabled: _vm.isDisabled(row.item),
                                          checked:
                                            row.item.id === 1 ||
                                            _vm.permissionsState(
                                              row.item,
                                              key
                                            ) === 1,
                                          indeterminate:
                                            _vm.permissionsState(
                                              row.item,
                                              key
                                            ) === -1
                                        },
                                        on: {
                                          change: function(checked) {
                                            return _vm.toggleAll(
                                              row.item,
                                              key,
                                              checked
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value: row.item.name + key,
                                              expression: "row.item.name + key"
                                            }
                                          ],
                                          attrs: { href: "#" + key }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("translate." + key)
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              ref: key,
                              refInFor: true,
                              attrs: { id: row.item.name + key }
                            },
                            [
                              _c(
                                "b-card-body",
                                _vm._l(permsGroup, function(perm) {
                                  return _c(
                                    "b-checkbox",
                                    {
                                      key: perm,
                                      attrs: {
                                        value:
                                          row.item.id === 1
                                            ? "*"
                                            : key + "." + perm,
                                        disabled: _vm.isDisabled(row.item),
                                        switch: ""
                                      },
                                      model: {
                                        value: row.item.permissions,
                                        callback: function($$v) {
                                          _vm.$set(row.item, "permissions", $$v)
                                        },
                                        expression: "row.item.permissions"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        { staticClass: "font-weight-normal" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "permission." +
                                                    key +
                                                    "." +
                                                    perm
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "top-actions" },
            [
              _c(
                "b-btn",
                {
                  attrs: { variant: "success" },
                  on: { click: _vm.createItem }
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "user-shield" } }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.create")) + "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }