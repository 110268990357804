var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-lte-header",
    { attrs: { "border-bottom": "" } },
    [
      _c(
        "b-navbar",
        {
          staticClass: "navbar-expand",
          attrs: { type: "light", variant: "white" }
        },
        [
          _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "a-lte-aside-toggle",
                      rawName: "v-a-lte-aside-toggle"
                    }
                  ]
                },
                [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$route.meta.searchable
            ? _c(
                "b-nav-form",
                { staticClass: "ml-3" },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control-navbar",
                        attrs: { placeholder: "Поиск..." },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "navbar" },
                              on: { click: _vm.onSearch }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "search" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-nav-item-dropdown",
                { attrs: { "no-caret": "", right: "" } },
                [
                  _c(
                    "template",
                    { slot: "button-content" },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "bell" } }),
                      _vm._v(" "),
                      _vm.notifs.length
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "navbar-badge",
                              attrs: { variant: "warning" }
                            },
                            [_vm._v(_vm._s(_vm.notifs.length))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.notifs.length
                    ? _vm._l(_vm.notifs, function(notif) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: notif.id,
                            on: {
                              click: function($event) {
                                return _vm.onNotif(notif)
                              }
                            }
                          },
                          [
                            notif.type === "certsTransfer"
                              ? _c("div", { staticClass: "media" }, [
                                  _c("img", {
                                    staticClass: "img-size-50 mr-3 img-circle",
                                    attrs: {
                                      src: _vm.$global.userAvatar(notif.sender),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "media-body" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(notif.sender.fullName) +
                                        "\n                "
                                    ),
                                    _c("p", { staticClass: "text-sm mb-0" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("notif." + notif.type))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-sm text-muted mb-0"
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "clock" }
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              new Date(
                                                notif.createdAt
                                              ).toLocaleDateString()
                                            ) +
                                            "\n                "
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        )
                      })
                    : _c("b-dropdown-item", [
                        _c("p", { staticClass: "text-sm mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("none")))
                        ])
                      ])
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$global.auth.logout()
                    }
                  }
                },
                [_c("font-awesome-icon", { attrs: { icon: "sign-out-alt" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }