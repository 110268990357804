var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-md-center mt-5 mb-5" },
        [
          _c("b-col", { attrs: { md: "auto", col: "" } }, [
            _c("h1", { staticClass: "display-1 text-warning text-center" }, [
              _vm._v("404")
            ])
          ]),
          _vm._v(" "),
          _c("b-col", { attrs: { md: "4", col: "", "align-self": "center" } }, [
            _c(
              "h4",
              [
                _c("font-awesome-icon", {
                  staticClass: "text-warning",
                  attrs: { icon: "exclamation-triangle" }
                }),
                _vm._v("\n        " + _vm._s(_vm.$t("notFound")) + "\n      ")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }