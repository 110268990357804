<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
        @toggle-details="itemClicked"
      >
        <template slot="top-actions">
          <b-btn variant="success" @click="createVehicle">
            <font-awesome-icon icon="plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template v-slot:cell(photo)="row">
          <div class="position-relative">
            <viewer
              v-if="!photosEmpty(row.item)"
              :trigger="row.item.photos"
              rebuild
              @inited="setViewer"
            >
              <div
                v-for="(src, i) in row.item.photos"
                :key="src"
                class="position-absolute"
                style="left: 50%;"
              >
                <img
                  v-b-tooltip.hover
                  v-show="i === 0"
                  :src="$global.storagePath('vehicles', row.item.id, src)"
                  :title="row.item.photos.length > 1 ? `еще ${row.item.photos.length-1} фото` : undefined"
                  class="photo-field position-relative"
                  @mouseover="overImageViewer = true"
                  @mouseleave="overImageViewer = false"
                />
              </div>
            </viewer>
            <b-btn
              :pill="true"
              :class="!photosEmpty(row.item) ? ['position-absolute', 'mt-3', 'ml-2'] : undefined"
              :size="!photosEmpty(row.item) ? 'xs' : 'sm'"
              @click="$refs[`file-${row.item.id}`].$el.querySelector(`input[type=file]`).click()"
            >
              <font-awesome-icon v-if="photosEmpty(row.item)" icon="camera" />
              <font-awesome-icon icon="plus" size="xs" />
            </b-btn>
            <b-file
              :ref="`file-${row.item.id}`"
              class="position-absolute invisible"
              style="width: 0;"
              accept=".png, .jpg"
              multiple
              @input="files => uploadPhotos(row.item, files)"
            />
          </div>
        </template>

        <template v-slot:cell(state)="row">
          <b-form-input
            v-b-tooltip.hover
            :id="`state-range-${row.id}`"
            v-model="row.item.stateIndex"
            :title="$t(`state.${row.item.stateIndex}`)"
            class="pr-3 pl-3"
            type="range"
            min="0"
            max="3"
            step="1"
            @input="toggleState(row.item, false)"
          />
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.state')"
            :pill="true"
            :variant="['success', 'warning', 'danger', 'info'][row.item.stateIndex]"
            @click="toggleState(row.item)"
          >
            <font-awesome-icon :icon="['warehouse', 'location-arrow', 'cogs', 'road'][row.item.stateIndex]" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.edit')"
            variant="info"
            @click="$refs.table.toggleEdit(row.item)"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="confirmEdit(row.item, row.index)"
          >
            <font-awesome-icon icon="check" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.delete')"
            variant="danger"
            @click="deleteVehicle(row.item)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-row>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.frame')">
                <span v-if="row.item.frame && !row.item._edit">
                  {{ row.item.frame }}
                </span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="6" >
                    <b-form-input
                      v-model="row.item.frame"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.engine')">
                <span v-if="row.item.engine && !row.item._edit">
                  {{ row.item.engine }}
                </span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="6" >
                    <b-form-input
                      v-model="row.item.engine"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.description')">
                <span
                  v-if="row.item.description && !row.item._edit"
                  style="white-space: pre;"
                >{{ row.item.description }}</span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="10">
                    <b-form-textarea
                      v-model="row.item.description"
                      :placeholder="$t('header.description')"
                      size="sm"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
          </b-row>
        </template>
      </q-table>
    </b-card>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

const stateIndexes = ['idle', 'rental', 'repair', 'run-in']

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],

      newItem: {},
      currentItem: {},
      overImageViewer: false,

      rentalPoints: [],

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'photo',
          label: this.$t('field.photo'),
          thStyle: { width: '100px' }
        },
        {
          key: 'name',
          label: this.$t('field.name'),
          sortable: true,
          editable: true,
          validator: v => !!v
        },
        {
          key: 'rentalPoint',
          label: this.$t('field.rentalPoint'),
          sortable: true,
          editable: true,
          selectable: true,
          thStyle: { width: '200px' },

          selectOptions: () => this.rentalPoints.map(rp => ({
            value: rp,
            text: rp.name
          })),

          validator: v => !!v,
          formatter: v => v.name
        },
        {
          key: 'state',
          label: this.$t('field.state'),
          formatter: () => this.$t('state.0')
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  async mounted() {
    try {
      const { data: vehiclesData } = await this.$api.vehicles.list()
      this.items = vehiclesData.vehicles || []
      this.items.forEach(v => {
        v.stateIndex = stateIndexes.indexOf(v.state)
      })

      const { data: rpData } = await this.$api.rentalPoints.list()
      this.rentalPoints = rpData.rentalPoints || []
      this.rentalPoints.forEach(rp => {
        delete rp.createdAt
        delete rp.updatedAt
      })

      this.$refs.table.sort('createdAt', false)
      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        name: '',
        state: 'idle'
      }
    },
    itemClicked(item) {
      this.currentItem = item

      if (this.overImageViewer) {
        // invert _showDetails flag to prevent opening details
        // and to hide details forcibly
        this.$set(item, '_showDetails', true)
      }
    },
    photosEmpty(item) {
      return !item.photos || !item.photos.length
    },
    setViewer(viewer) {
      this.$viewer = viewer
      this.$viewer.reset = this.deletePhoto
    },

    async createVehicle() {
      try {
        this.loading = true
        this.newItem.rentalPointId = this.newItem.rentalPoint.id
        this.newItem.rentalPoint = undefined
        const { data } = await this.$api.vehicles.create(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id
        item._edit = true
        item._showDetails = true
        item.stateIndex = 0
        item.rentalPoint = this.rentalPoints.find(rp => rp.id === item.rentalPointId)

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('vehicle.created', 'successInfo')
        this.$refs.table.sort('createdAt', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        this.$refs.table.restoreItem(index)
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      if (this.$refs.table.isItemCached(item)) {
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        item.rentalPointId = item.rentalPoint.id
        await this.$api.vehicles.update(item)
        this.$global.toast('vehicle.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    deleteVehicle(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.vehicles.delete(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('vehicle.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    },
    uploadPhotos(item, files) {
      files.forEach(file => this.uploadPhoto(item, file))
    },
    async uploadPhoto(item, file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert(this.$t('error.invalidType')) // eslint-disable-line
        return
      }
      if (file.size >= this.$global.maxImageSize) {
        alert(this.$t('error.tooBig')) // eslint-disable-line
        return
      }

      const data = new FormData()
      data.append('file', file)

      const { data: photoData } = await this.$api.vehicles.uploadPhoto(item.id, data)
      if (!item.photos) item.photos = [photoData.hashext]
      else item.photos.push(photoData.hashext)
      this.$refs.table.refresh()
    },
    async toggleState(item, update = true) {
      if (update) {
        item.stateIndex = Number.parseInt(item.stateIndex, 10) + 1
        if (item.stateIndex === stateIndexes.length) {
          item.stateIndex = 0
        }
      }

      try {
        item.state = stateIndexes[item.stateIndex]
        await this.$api.vehicles.updateState(item.id, item.state)
      } catch (e) {
        console.log(e)
      }
    },
    async deletePhoto() {
      try {
        const photo = this.currentItem.photos[this.$viewer.index]
        await this.$api.vehicles.deletePhoto(this.currentItem.id, photo)
        this.currentItem.photos = this.currentItem.photos.filter(p => p !== photo)
      } catch (e) {
        console.log(e)
        // eslint-disable-prev-line no-empty
      }
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  state: Переключить состояние
  create: Создать
  edit: Редактировать
  confirm: Подтвердить
  delete: Удалить
field:
  createdAt: Создан
  photo: Фото
  name: Модель
  rentalPoint: Точка проката
  state: Состояние
  actions: Действия
header:
  frame: Рама
  engine: Двигатель
  description: Описание
state:
  0: в гараже
  1: прокат
  2: ремонт
  3: обкатка
modal:
  delete:
    message: Вы уверены, что хотите удалить эту запись?
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
empty: Пусто
</i18n>
