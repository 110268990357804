var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-lte-layout-login",
    [
      _c(
        "router-link",
        { attrs: { slot: "header", to: "/" }, slot: "header" },
        [_c("strong", [_vm._v("LLC QuadroClub")]), _vm._v(" Ukraine\n  ")]
      ),
      _vm._v(" "),
      _vm.errorCode
        ? _c("p", { staticClass: "text-center text-danger" }, [
            _c("b", [
              _vm._v(
                _vm._s(
                  _vm.$te("error." + _vm.errorCode)
                    ? _vm.$t("error." + _vm.errorCode)
                    : _vm.$t("error.default")
                )
              )
            ])
          ])
        : _c("p", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.$t("welcome")))
          ]),
      _vm._v(" "),
      _c(
        "b-form",
        {
          staticClass: "mb-2",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-text",
                    { attrs: { slot: "prepend" }, slot: "prepend" },
                    [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      name: "email",
                      type: "email",
                      placeholder: "Email",
                      required: ""
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-text",
                    { attrs: { slot: "prepend" }, slot: "prepend" },
                    [_c("font-awesome-icon", { attrs: { icon: "key" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      name: "password",
                      type: "password",
                      placeholder: "Пароль",
                      required: ""
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { slot: "description", to: "" }, slot: "description" },
                [_vm._v(_vm._s(_vm.$t("forgotPassword")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", type: "submit", block: "" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("enter")) + "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }