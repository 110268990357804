var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { staticClass: "breadcrumb", attrs: { name: _vm.transition, tag: "ol" } },
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "li",
        {
          key: item.path,
          class: { "breadcrumb-item": true, active: _vm.isLast(index) }
        },
        [
          _vm.isLast(index)
            ? [_vm._v(_vm._s(_vm.showName(item)))]
            : _c("router-link", { attrs: { to: item } }, [
                _vm._v(_vm._s(_vm.showName(item)))
              ])
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }