<template functional>
  <nav v-bind="data.attrs" v-on="listeners">
    <ul
      :class="{
        nav: true,
        'nav-sidebar': true,
        'nav-pills': true,
        'flex-column': true,
        'nav-flat': props.flat,
        'nav-legacy': props.legacy,
        'nav-compact': props.compact,
        'nav-child-indent': props.childIndent
      }"
      data-widget="treeview"
      role="menu"
      style="overflow: hidden;"
    >
      <slot />
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    legacy: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    childIndent: {
      type: Boolean,
      default: false
    }
  }
}
</script>
