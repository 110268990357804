<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
        @toggle-details="itemClicked"
      >
        <template slot="top-actions">
          <b-btn variant="success" @click="createInventory">
            <font-awesome-icon icon="plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template v-slot:cell(photo)="row">
          <div class="position-relative">
            <viewer
              v-if="!photosEmpty(row.item)"
              :trigger="row.item.photos"
              rebuild
              @inited="setViewer"
            >
              <div
                v-for="(src, i) in row.item.photos"
                :key="src"
                class="position-absolute"
                style="left: 50%;"
              >
                <img
                  v-b-tooltip.hover
                  v-show="i === 0"
                  :src="$global.storagePath('inventory', row.item.id, src)"
                  :title="row.item.photos.length > 1 ? `еще ${row.item.photos.length-1} фото` : undefined"
                  class="photo-field position-relative"
                  @mouseover="overImageViewer = true"
                  @mouseleave="overImageViewer = false"
                />
              </div>
            </viewer>
            <b-button
              :pill="true"
              :class="!photosEmpty(row.item) ? ['position-absolute', 'mt-3', 'ml-2'] : undefined"
              :size="!photosEmpty(row.item) ? 'xs' : 'sm'"
              @click="$refs[`file-${row.item.id}`].$el.querySelector(`input[type=file]`).click()"
            >
              <font-awesome-icon v-if="photosEmpty(row.item)" icon="camera" />
              <font-awesome-icon icon="plus" size="xs" />
            </b-button>
            <b-file
              :ref="`file-${row.item.id}`"
              class="position-absolute invisible"
              style="width: 0;"
              accept=".png, .jpg"
              multiple
              @input="files => uploadPhotos(row.item, files)"
            />
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.edit')"
            variant="info"
            @click="$refs.table.toggleEdit(row.item)"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="confirmEdit(row.item, row.index)"
          >
            <font-awesome-icon icon="check" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.delete')"
            variant="danger"
            @click="deleteInventory(row.item)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-row>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.owner')">
                <template v-if="!row.item._edit && row.item.owner">
                  {{ row.item.owner.fullName }}
                </template>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="4">
                    <b-form-select
                      v-model="row.item.owner"
                      :options="usersOptions"
                      size="sm"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.description')">
                <span
                  v-if="row.item.description && !row.item._edit"
                  style="white-space: pre;"
                >{{ row.item.description }}</span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="8">
                    <b-form-textarea
                      v-model="row.item.description"
                      :placeholder="$t('header.description')"
                      size="sm"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
          </b-row>
        </template>
      </q-table>
    </b-card>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

const inventoryTypes = 'inventory,consumable'
const offset = 0
const limit = 1000 // hard-coded for now

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],

      newItem: {},
      currentItem: {},
      overImageViewer: false,

      stocks: [],
      users: [],

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'photo',
          label: this.$t('field.photo'),
          thStyle: { width: '100px' }
        },
        {
          key: 'type',
          label: this.$t('field.type'),
          sortable: true,
          editable: true,
          selectable: true,
          thStyle: { width: '200px' },
          formatter: v => this.$t(`type.${v}`),

          selectOptions: () => [
            { value: 'inventory', text: this.$t('type.inventory') },
            { value: 'consumable', text: this.$t('type.consumable') }
          ]
        },
        {
          key: 'stock',
          label: this.$t('field.stock'),
          sortable: true,
          editable: true,
          selectable: true,
          thStyle: { width: '200px' },
          formatter: v => v.name,

          selectOptions: () => this.stocks.map(s => ({
            value: s,
            text: s.name
          }))
        },
        {
          key: 'name',
          label: this.$t('field.name'),
          sortable: true,
          editable: true
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  computed: {
    usersOptions() {
      return [
        {
          value: null,
          text: ''
        },
        ...this.users.map(u => ({
          value: u,
          text: u.fullName
        }))
      ]
    }
  },
  created() {
    this.$eventBus.$on('search', async (query) => {
      this.loading = true

      try {
        const { data } = await this.$api.stocks.listInventory(offset, limit, inventoryTypes, query)
        this.items = data.items || []
      } catch (e) {
        this.error = true
      } finally {
        this.$refs.table.refresh()
        this.loading = false
      }
    })
  },
  async mounted() {
    try {
      const { data: inventoryData } = await this.$api.stocks.listInventory(offset, limit, inventoryTypes)
      this.items = inventoryData.items || []

      const { data: stocksData } = await this.$api.stocks.list()
      this.stocks = stocksData.stocks || []
      this.stocks.forEach(s => delete s.createdAt)
      this.stocks.forEach(s => delete s.updatedAt)

      const { data: usersData } = await this.$api.users.list()
      this.users = usersData.users || []
      this.users.forEach(u => delete u.rentalPoints)

      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        name: '',
        description: '',
        type: '',
        count: 1
      }
    },
    itemClicked(item) {
      this.currentItem = item

      if (this.overImageViewer) {
        // invert _showDetails flag to prevent opening details
        // and to hide details forcibly
        this.$set(item, '_showDetails', true)
      }
    },
    photosEmpty(item) {
      return !item.photos || !item.photos.length
    },
    setViewer(viewer) {
      this.$viewer = viewer
      this.$viewer.reset = this.deletePhoto
    },

    async createInventory() {
      if (!this.newItem.stock) {
        this.$global.toast('dataInvalid')
        return
      }

      try {
        this.loading = true
        this.newItem.stockId = this.newItem.stock.id
        const { data } = await this.$api.stocks.createInventory(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('inventory.created', 'successInfo')
        this.$refs.table.sort('createdAt', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        this.$refs.table.restoreItem(index)
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      if (this.$refs.table.isItemCached(item)) {
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        item.ownerId = item.owner.id
        await this.$api.stocks.updateInventory(item)
        this.$global.toast('inventory.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    async deleteInventory(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.stocks.deleteInventory(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('inventory.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    },
    uploadPhotos(item, files) {
      files.forEach(file => this.uploadPhoto(item, file))
    },
    async uploadPhoto(item, file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert(this.$t('error.invalidType')) // eslint-disable-line
        return
      }
      if (file.size >= this.$global.maxImageSize) {
        alert(this.$t('error.tooBig')) // eslint-disable-line
        return
      }

      const data = new FormData()
      data.append('file', file)

      const { data: photoData } = await this.$api.stocks.uploadInventoryPhoto(item.id, data)
      if (!item.photos) item.photos = [photoData.hashext]
      else item.photos.push(photoData.hashext)
      this.$refs.table.refresh()
    },
    async deletePhoto() {
      try {
        const photo = this.currentItem.photos[this.$viewer.index]
        await this.$api.stocks.deleteInventoryPhoto(this.currentItem.id, photo)
        this.currentItem.photos = this.currentItem.photos.filter(p => p !== photo)
      } catch (e) {
        // eslint-disable-prev-line no-empty
      }
    }
  }
}
</script>

<style>

</style>

<i18n locale="ru" lang="yaml">
button:
  create: Создать
  edit: Редактировать
  confirm: Подтвердить
  delete: Удалить
field:
  createdAt: Создан
  photo: Фото
  name: Название
  type: Тип
  stock: Склад
  actions: Действия
header:
  owner: Владелец
  description: Описание
type:
  inventory: Инвентарь
  consumable: Расходники
error:
  invalidType: Неверный тип файла!
  tooBig: Максимальный размер загружаемого фото — 64 МБ
modal:
  delete:
    message: Вы уверены, что хотите удалить этого пользователя?
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
empty: Пусто
</i18n>
