var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "sm-12" },
    [
      _c("FullCalendar", { ref: "calendar", attrs: { options: _vm.calendar } }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modalSchedule",
        attrs: {
          id: "modal-schedule-create",
          "ok-title": _vm.selectedEvent.id ? "Сохранить изменения" : "Добавить",
          title: "Добавить запись",
          "cancel-title": "Отменить"
        },
        on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.postScheduleEntry($event)
                      }
                    }
                  },
                  [
                    _vm._l(_vm.fields, function(f) {
                      return _vm.checkDependency(f) === true
                        ? _c(
                            "b-form-group",
                            {
                              key: f.key,
                              attrs: {
                                state: _vm.modalState,
                                "invalid-feedback": "invalid feedback",
                                "valid-feedback": "valid"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.hasPrepend(f)
                                        ? {
                                            key: "prepend",
                                            fn: function() {
                                              return [
                                                f.dropdownOptions
                                                  ? _c(
                                                      "b-dropdown",
                                                      {
                                                        staticStyle: {
                                                          width: "42px"
                                                        },
                                                        attrs: {
                                                          "no-caret": ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "button-content",
                                                              fn: function() {
                                                                return [
                                                                  f.dropdownValue
                                                                    ? _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          attrs: {
                                                                            icon: _vm.dropdownOption(
                                                                              f
                                                                            )
                                                                              .icon
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          f.dropdownOptions,
                                                          function(d) {
                                                            return _c(
                                                              "b-dropdown-item",
                                                              {
                                                                key: d.value,
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    f.dropdownValue =
                                                                      d.value
                                                                    _vm.selectedEvent[
                                                                      f.dropdownKey
                                                                    ] =
                                                                      d.value
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      icon:
                                                                        d.icon
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  "\n                  " +
                                                                    _vm._s(
                                                                      d.text
                                                                    ) +
                                                                    "\n                "
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : f.button
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          attrs: { id: f.key },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.buttonClick(
                                                                f.key
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: f.button
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticStyle: {
                                                            width: "42px"
                                                          }
                                                        },
                                                        [
                                                          f.icon
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: f.icon
                                                                  }
                                                                }
                                                              )
                                                            : f.checkbox
                                                              ? _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "mr-n2",
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .selectedEvent[
                                                                          f
                                                                            .checkbox
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.selectedEvent,
                                                                          f.checkbox,
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "selectedEvent[f.checkbox]"
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                        ],
                                                        1
                                                      )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null,
                                      f.append
                                        ? {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c("b-input-group-text", [
                                                      _vm._v(_vm._s(f.append))
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  f.selectOptions !== undefined &&
                                  f.selectType === "multiple"
                                    ? _c("b-form-tags", {
                                        attrs: {
                                          id: "tags-component-select",
                                          size: "lg",
                                          "add-on-change": "",
                                          "no-outer-focus": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var tags = ref.tags
                                                var inputAttrs = ref.inputAttrs
                                                var inputHandlers =
                                                  ref.inputHandlers
                                                var disabled = ref.disabled
                                                var removeTag = ref.removeTag
                                                return [
                                                  _c(
                                                    "b-form-select",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled: disabled,
                                                            options: f.selectOptions()
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "first",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                          value:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            f.label
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        "b-form-select",
                                                        inputAttrs,
                                                        false
                                                      ),
                                                      inputHandlers
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  tags.length > 0
                                                    ? _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "list-inline d-inline-block mt-2 mb-0"
                                                        },
                                                        _vm._l(tags, function(
                                                          tag
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: tag,
                                                              staticClass:
                                                                "list-inline-item"
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-tag",
                                                                {
                                                                  attrs: {
                                                                    title: tag,
                                                                    disabled: disabled,
                                                                    variant:
                                                                      "info"
                                                                  },
                                                                  on: {
                                                                    remove: function(
                                                                      $event
                                                                    ) {
                                                                      return removeTag(
                                                                        tag
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm.findTranslation(
                                                                          f,
                                                                          tag
                                                                        )
                                                                      ) +
                                                                      "\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }),
                                                        0
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.selectedEvent[f.key],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedEvent,
                                              f.key,
                                              $$v
                                            )
                                          },
                                          expression: "selectedEvent[f.key]"
                                        }
                                      })
                                    : f.selectOptions !== undefined &&
                                      f.selectType !== "multiple"
                                      ? _c("b-form-select", {
                                          attrs: { options: f.selectOptions() },
                                          model: {
                                            value: _vm.selectedEvent[f.key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.selectedEvent,
                                                f.key,
                                                $$v
                                              )
                                            },
                                            expression: "selectedEvent[f.key]"
                                          }
                                        })
                                      : [String, Number].includes(f.type) &&
                                        !f.textarea &&
                                        !f.file &&
                                        !f.selectOptions
                                        ? _c("b-form-input", {
                                            attrs: {
                                              type:
                                                f.type === Number
                                                  ? "number"
                                                  : "text",
                                              required: f.required,
                                              disabled:
                                                f.checkbox &&
                                                !_vm.selectedEvent[f.checkbox],
                                              value: _vm.selectedEvent[f.key]
                                                ? _vm.selectedEvent[f.key]
                                                : "",
                                              placeholder: f.label
                                            },
                                            model: {
                                              value: _vm.selectedEvent[f.key],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.selectedEvent,
                                                  f.key,
                                                  $$v
                                                )
                                              },
                                              expression: "selectedEvent[f.key]"
                                            }
                                          })
                                        : f.textarea
                                          ? _c("b-form-textarea", {
                                              attrs: {
                                                id: "textarea-small",
                                                value: _vm.selectedEvent[f.key],
                                                placeholder: f.label,
                                                size: "md"
                                              },
                                              model: {
                                                value: _vm.selectedEvent[f.key],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedEvent,
                                                    f.key,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedEvent[f.key]"
                                              }
                                            })
                                          : f.file
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "position-relative"
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      class: !_vm.selectedEvent[
                                                        f.key
                                                      ]
                                                        ? [
                                                            "position-absolute",
                                                            "mt-3",
                                                            "ml-2"
                                                          ]
                                                        : undefined,
                                                      attrs: {
                                                        pill: true,
                                                        size: !_vm
                                                          .selectedEvent[f.key]
                                                          ? "xs"
                                                          : "sm"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.$refs[
                                                            "file-" +
                                                              _vm.selectedEvent
                                                                .id
                                                          ].$el
                                                            .querySelector(
                                                              "input[type=file]"
                                                            )
                                                            .click()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      !_vm.selectedEvent[f.key]
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: "camera"
                                                              }
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "plus",
                                                          size: "xs"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.selectedEvent.id
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-danger", block: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteEntry(_vm.selectedEvent)
                              }
                            }
                          },
                          [_vm._v("\n          Удалить\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedEvent.prepayPhoto
                      ? _c(
                          "viewer",
                          {
                            staticClass: "collapse",
                            attrs: {
                              trigger:
                                _vm.selectedEvent.photo ||
                                _vm.selectedEvent.prepayPhoto,
                              rebuild: ""
                            },
                            on: { inited: _vm.setViewer }
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "photo-field position-relative",
                              attrs: {
                                src: _vm.$global.storagePath(
                                  "schedule",
                                  _vm.selectedEvent.id,
                                  _vm.selectedEvent.prepayPhoto
                                )
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("b-file", {
                      ref: "file-" + _vm.selectedEvent.id,
                      staticClass: "collapse",
                      staticStyle: { width: "0" },
                      attrs: { id: "photo_file", accept: ".png, .jpg" },
                      on: {
                        input: function(file) {
                          return _vm.uploadPhoto(_vm.selectedEvent.id, file)
                        }
                      },
                      model: {
                        value: _vm.selectedEvent.photo,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedEvent, "photo", $$v)
                        },
                        expression: "selectedEvent.photo"
                      }
                    })
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }