var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b({ staticClass: "content-wrapper" }, "div", _vm.data.attrs, false),
      _vm.listeners
    ),
    [
      _c("div", { staticClass: "content-header" }, [_vm._t("header")], 2),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }