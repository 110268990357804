<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
        @toggle-details="toggleDetails"
      >
        <template slot="top-actions">
          <b-btn variant="success" @click="createSpare">
            <font-awesome-icon icon="plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template v-slot:cell(photo)="row">
          <div class="position-relative">
            <viewer
              v-if="!photosEmpty(row.item)"
              :trigger="row.item.photos"
              rebuild
              @inited="setViewer"
            >
              <div
                v-for="(src, i) in row.item.photos"
                :key="src"
                class="position-absolute"
                style="left: 50%;"
              >
                <img
                  v-b-tooltip.hover
                  v-show="i === 0"
                  :src="$global.storagePath('inventory', row.item.id, src)"
                  :title="row.item.photos.length > 1 ? `еще ${row.item.photos.length-1} фото` : undefined"
                  class="photo-field position-relative"
                />
              </div>
            </viewer>
            <b-button
              :pill="true"
              :class="!photosEmpty(row.item) ? ['position-absolute', 'mt-3', 'ml-2'] : undefined"
              :size="!photosEmpty(row.item) ? 'xs' : 'sm'"
              @click="$refs[`file-${row.item.id}`].$el.querySelector(`input[type=file]`).click()"
            >
              <font-awesome-icon v-if="photosEmpty(row.item)" icon="camera" />
              <font-awesome-icon icon="plus" size="xs" />
            </b-button>
            <b-file
              :ref="`file-${row.item.id}`"
              class="position-absolute invisible"
              style="width: 0;"
              accept=".png, .jpg"
              multiple
              @input="files => uploadPhotos(row.item, files)"
            />
          </div>
        </template>

        <template v-slot:cell(description)="row">
          <span v-if="row.item.description && !row.item._edit">
            {{ row.item.description }}
          </span>
          <b-form-input
            v-else-if="row.item._edit"
            v-model="row.item.description"
            class="text-center"
            size="sm"
          />
          <i v-else>{{ $t('empty') }}</i>
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.edit')"
            variant="info"
            @click="$refs.table.toggleEdit(row.item)"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="confirmEdit(row.item, row.index)"
          >
            <font-awesome-icon icon="check" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.delete')"
            variant="danger"
            @click="deleteInventory(row.item)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
        </template>

        <template slot="row-details" slot-scope="row">
          <h6 class="text-muted">{{ $t('sorting') }}</h6>
          <template v-if="row.item.stocks && row.item.stocks.length">
            <b-row
              v-for="stock in row.item.stocks"
              :key="stock.id"
              class="mt-1"
            >
              <b-col cols="6" xs="3">
                <b class="float-right">{{ stock.name }}</b>
              </b-col>
              <b-col cols="2" md="1">
                <b-form-input
                  v-model="stock.count"
                  :disabled="!row.item._edit"
                  type="number"
                  size="sm"
                  min="0"
                  number
                  @keydown="isNumber"
                />
              </b-col>
            </b-row>
          </template>
          <i v-else>{{ $t('empty') }}</i>
        </template>
      </q-table>
    </b-card>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

const inventoryTypes = 'spare'
const offset = 0
const limit = 1000 // hard-coded for now

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],

      newItem: {},
      currentItem: {},

      stocks: [],

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'photo',
          label: this.$t('field.photo'),
          thStyle: { width: '100px' }
        },
        {
          key: 'name',
          label: this.$t('field.name'),
          sortable: true,
          editable: true
        },
        {
          key: 'number',
          label: this.$t('field.number'),
          sortable: true,
          editable: true
        },
        {
          key: 'description',
          label: this.$t('field.description'),
          sortable: true,
          editable: true
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  created() {
    this.$eventBus.$on('search', async (query) => {
      this.loading = true

      try {
        const { data } = await this.$api.stocks.listInventory(offset, limit, inventoryTypes, query)
        this.items = data.items || []
      } catch (e) {
        this.error = true
      } finally {
        this.$refs.table.refresh()
        this.loading = false
      }
    })
  },
  async mounted() {
    try {
      const { data: inventoryData } = await this.$api.stocks.listInventory(offset, limit, inventoryTypes)
      this.items = inventoryData.items || []

      const { data: stocksData } = await this.$api.stocks.list()
      this.stocks = stocksData.stocks || []
      this.stocks.forEach(s => delete s.createdAt)
      this.stocks.forEach(s => delete s.updatedAt)

      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        name: '',
        type: 'spare',
        number: '',
        description: ''
      }
    },
    photosEmpty(item) {
      return !item.photos || !item.photos.length
    },
    setViewer(viewer) {
      this.$viewer = viewer
      this.$viewer.reset = this.deletePhoto
    },
    isNumber(e) {
      return /^[0-9]+$/.test(String.fromCharCode(e.keyCode)) || e.preventDefault()
    },

    async toggleDetails(item) {
      this.currentItem = item

      // check previous state
      if (!item._showDetails) {
        try {
          const { data } = await this.$api.stocks.listInventoryStocks(item.id)
          if (!data.stocks) data.stocks = []

          item.stocks = this.stocks.map(s => {
            const is = data.stocks.find(x => x.stockId === s.id)
            return { ...s, count: (is && is.count ? is.count : 0) }
          })
        } catch (e) {
          // eslint-disable-prev-line no-empty
        }
      }
    },

    async createSpare() {
      try {
        this.loading = true
        const { data } = await this.$api.stocks.createInventory(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id
        this.items.push(item)

        this.newItem = this.defaultNewItem()
        this.$global.toast('inventory.created', 'successInfo')
        this.$refs.table.sort('createdAt', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        this.$refs.table.restoreItem(index)
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        item.stocks.forEach(s => { s.stockId = s.id })
        await this.$api.stocks.updateInventory(item)
        this.$global.toast('inventory.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    async deleteInventory(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.stocks.deleteInventory(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('inventory.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    },
    uploadPhotos(item, files) {
      files.forEach(file => this.uploadPhoto(item, file))
    },
    async uploadPhoto(item, file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert(this.$t('error.invalidType')) // eslint-disable-line
        return
      }
      if (file.size >= this.$global.maxImageSize) {
        alert(this.$t('error.tooBig')) // eslint-disable-line
        return
      }

      const data = new FormData()
      data.append('file', file)

      const { data: photoData } = await this.$api.stocks.uploadInventoryPhoto(item.id, data)
      if (!item.photos) item.photos = [photoData.hashext]
      else item.photos.push(photoData.hashext)
      this.$refs.table.refresh()
    },
    async deletePhoto() {
      try {
        const photo = this.currentItem.photos[this.$viewer.index]
        await this.$api.stocks.deleteInventoryPhoto(this.currentItem.id, photo)
        this.currentItem.photos = this.currentItem.photos.filter(p => p !== photo)
      } catch (e) {
        // eslint-disable-prev-line no-empty
      }
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  create: Создать
  edit: Редактировать
  confirm: Подтвердить
  delete: Удалить
field:
  createdAt: Создан
  photo: Фото
  name: Название
  number: Каталожный номер
  description: Описание
  actions: Действия
error:
  invalidType: Неверный тип файла!
  tooBig: Максимальный размер загружаемого фото — 64 МБ
modal:
  delete:
    message: Вы уверены, что хотите удалить этого пользователя?
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
empty: Пусто
sorting: Сортировка по складам
</i18n>
