var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "brand-link", attrs: { to: _vm.to } },
    [
      _c("img", {
        class: ["brand-image", "img-circle", _vm.elevationClass],
        attrs: {
          src: _vm.logo,
          srcset: (_vm.logo2x || _vm.logo) + " 2x",
          alt: _vm.header
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "brand-text font-weight-light" }, [
        _vm._v(_vm._s(_vm.header))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }