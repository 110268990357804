var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-lte-footer", [
    _vm._v("\n  Copyright © "),
    _c("strong", [_vm._v("LLC Quadroclub 2021")]),
    _vm._v(". All rights reserved.\n  "),
    _c("div", { staticClass: "float-right d-none d-sm-inline-block" }, [
      _c("strong", [_vm._v("\n      " + _vm._s(_vm.version) + "\n      ")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }