import Aside from './aside'
import Breadcrumbs from './breadcrumbs'
import Card from './card'
import InfoBox from './info-box'
import Layout from './layout'
import Miscellaneous from './miscellaneous'
import SmallBox from './small-box'

export default {
  Aside,
  Breadcrumbs,
  Card,
  Miscellaneous,
  InfoBox,
  Layout,
  SmallBox
}
