import i18n from '@/plugins/i18n'
import Toast from '@/components/Toast.vue'

// set toast i18n messages directly from Toast component
i18n.setLocaleMessage('ru', JSON.parse(Toast.__i18n[0]).ru)

function $global(Vue, store, router) {
  const vm = new Vue({ i18n })

  const toasts = {
    noPermissions: {
      variant: 'warning'
    },
    error: {
      variant: 'danger'
    },
    dataInvalid: {
      variant: 'info',
      autoHideDelay: 1000
    },
    successInfo: {
      variant: 'success',
      autoHideDelay: 500
    }
  }

  return {
    maxImageSize: 64 * 1024 * 1024, // 64 MB

    userAvatar(user) {
      return (user && user.avatar)
        ? this.storagePath('avatars', user.id, user.avatar)
        : '/images/default-avatar.jpg'
    },

    storagePath(bucket, id, hashext) {
      return `${process.env.STORAGE_URL}/${bucket}?p=${id}/${hashext}`
    },

    hasPermissions(perm, permsToCheck = undefined) {
      const perms = permsToCheck || store.state.user.group.permissions || []
      return perms.some(p => p === perm) || perms.some(p => p === '*')
    },

    isSuperAdmin() {
      return store.state.user.group.id === 1
    },

    rangeString(ids) {
      if (ids.length === 0) {
        return ''
      }

      const ranges = []
      ids.slice()
        .sort((a, b) => a > b)
        .forEach((v, i, arr) => {
          if (i > 0 && v - arr[i - 1] === 1) {
            ranges[ranges.length - 1].push(v)
          } else {
            ranges.push([v])
          }
        })

      return ranges
        .map(v => (v.length > 1
          ? `${v[0]}..${v[v.length - 1]}`
          : v[0].toString()))
        .join(', ')
    },

    // key is i18n key for the toast
    // toastKey is a special key describes appearance of the toast
    toast(key, toastKey = key) {
      const toast = toasts[toastKey]
      vm.$bvToast.toast(vm.$t(`toast.${key}`), {
        title: vm.$t(`variant.${toast.variant}`),
        ...toast
      })
    },

    auth: {
      logout() {
        store.commit('setUser', null)
        store.commit('setToken', null)
        router.push('/login')
      }
    }
  }
}

export default {
  install(Vue, store, router) {
    Vue.prototype.$global = $global(Vue, store, router)
  }
}
