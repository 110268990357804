<template>
  <aside
    v-click-outside="toggle"
    :class="['main-sidebar', `sidebar-${type}-${variant}`, elevationClass]"
  >
    <slot />
  </aside>
</template>

<script>
import elevation from '../../mixins/elevation'

export default {
  mixins: [elevation()],
  props: {
    type: {
      type: String,
      default: 'dark'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    mini: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.mini) {
      document.body.classList.add('sidebar-mini')
    }
    if (this.collapse) {
      document.body.classList.add('sidebar-collapse')
    } else {
      document.body.classList.add('sidebar-open')
    }
  },
  methods: {
    toggle(event) {
      if (window.innerWidth > window.innerHeight) return

      const isNavbar = event.target.className === 'nav-link' ||
        event.target.nodeName === 'path'

      if (!isNavbar && document.body.classList.contains('sidebar-open')) {
        document.body.classList.toggle('sidebar-collapse')
        document.body.classList.toggle('sidebar-open')
      }
    }
  }
}
</script>
