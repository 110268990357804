var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "sm-12" },
        [
          _c(
            "q-table",
            {
              ref: "table",
              attrs: {
                loading: _vm.loading,
                error: _vm.error,
                items: _vm.items,
                fields: _vm.fields,
                "new-item": _vm.newItem
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(avatar)",
                  fn: function(row) {
                    return [
                      _c("img", {
                        staticClass: "img-circle w-50",
                        attrs: { src: _vm.$global.userAvatar(row.item) }
                      })
                    ]
                  }
                },
                {
                  key: "cell(actions)",
                  fn: function(row) {
                    return [
                      !row.item._edit
                        ? _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.edit"),
                                disabled:
                                  _vm.isDisabled(row.item) &&
                                  row.item.id !== _vm.$store.state.user.id,
                                variant: "info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.table.toggleEdit(row.item)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-edit" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.confirm"),
                                variant: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.confirmEdit(row.item, row.index)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-check" }
                              })
                            ],
                            1
                          ),
                      _vm._v(" "),
                      !row.item.banned
                        ? _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.ban"),
                                disabled: _vm.isDisabled(row.item),
                                variant: "danger"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setUserBanned(row.item, true)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-slash" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                title: _vm.$t("button.unban"),
                                disabled: _vm.isDisabled(row.item),
                                variant: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setUserBanned(row.item, false)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-lock" }
                              })
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            disabled: _vm.isDisabled(row.item),
                            title: _vm.$t("button.delete"),
                            variant: "danger"
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteUser(row.item)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-times" }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm4: "" } },
                            [
                              _c(
                                "a-lte-description-block",
                                { attrs: { header: _vm.$t("header.phone") } },
                                [
                                  row.item.phone && !row.item._edit
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.item.phone) +
                                            "\n              "
                                        )
                                      ])
                                    : row.item._edit
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "justify-content-center"
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "text-center",
                                                  attrs: { size: "sm" },
                                                  model: {
                                                    value: row.item.phone,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row.item,
                                                        "phone",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.item.phone"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("i", [
                                          _vm._v(_vm._s(_vm.$t("empty")))
                                        ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm4: "" } },
                            [
                              _c(
                                "a-lte-description-block",
                                {
                                  attrs: {
                                    header: _vm.$t("header.rentalPoints")
                                  }
                                },
                                [
                                  !row.item._edit
                                    ? _vm._l(row.item.rentalPoints, function(
                                        rp
                                      ) {
                                        return _c(
                                          "span",
                                          {
                                            key: rp.name,
                                            staticStyle: {
                                              "white-space": "pre"
                                            }
                                          },
                                          [_vm._v(_vm._s(rp.name)), _c("br")]
                                        )
                                      })
                                    : _c(
                                        "b-row",
                                        {
                                          staticClass: "justify-content-center"
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.rentalPointsOptions,
                                                  size: "sm"
                                                },
                                                model: {
                                                  value:
                                                    row.item.rentalPoints[0],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      row.item.rentalPoints,
                                                      0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.rentalPoints[0]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm4: "" } },
                            [
                              _c(
                                "a-lte-description-block",
                                { attrs: { header: _vm.$t("header.note") } },
                                [
                                  row.item.note && !row.item._edit
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "white-space": "pre" }
                                        },
                                        [_vm._v(_vm._s(row.item.note))]
                                      )
                                    : row.item._edit
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "justify-content-center"
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "header.note"
                                                    ),
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value: row.item.note,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row.item,
                                                        "note",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.item.note"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("i", [
                                          _vm._v(_vm._s(_vm.$t("empty")))
                                        ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "top-actions" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "success" },
                      on: { click: _vm.openModal }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user-plus" } }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.create")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modalPassword",
        attrs: {
          id: "modal-password",
          title: _vm.$t("modal.password.title"),
          "ok-title": _vm.$t("modal.password.ok"),
          "cancel-title": _vm.$t("modal.password.cancel")
        },
        on: {
          ok: _vm.handleModalOk,
          show: _vm.resetModal,
          hidden: _vm.resetModal
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.createUser($event)
                      }
                    }
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          state: _vm.modalState,
                          "invalid-feedback": _vm.$t(
                            "modal.password.invalid." +
                              _vm.modalInvalidFeedbackKey
                          ),
                          "valid-feedback": _vm.$t("modal.password.valid")
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: { state: _vm.modalState, required: "" },
                              model: {
                                value: _vm.modal.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.modal, "password", $$v)
                                },
                                expression: "modal.password"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "success" },
                                    on: { click: _vm.generateModalPassword }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("modal.password.button")
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }