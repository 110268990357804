import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '@/layouts/Main.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'

import Me from '@/views/Me.vue'
import Users from '@/views/Users.vue'
import Groups from '@/views/Groups.vue'
import CertGroups from '@/views/certs/CertGroups.vue'
import Certs from '@/views/certs/Certs.vue'
import Stocks from '@/views/stocks/Stocks.vue'
import StocksSpares from '@/views/stocks/StocksSpares.vue'
import StocksInventory from '@/views/stocks/StocksInventory.vue'
import Vehicles from '@/views/vehicles/Vehicles.vue'
import SchedulePoints from '@/views/schedule/SchedulePoints.vue'
import ScheduleServices from '@/views/schedule/ScheduleServices.vue'
import ScheduleCalendar from '@/views/schedule/ScheduleCalendar.vue'
import Journal from '@/views/Journal.vue'

Vue.use(VueRouter)

function prefixRoutes(prefix, routes) {
  return routes.map(route => {
    route.path = `${prefix}/${route.path}`
    return route
  })
}

const routes = [
  {
    path: '/',
    component: Main,
    meta: { label: 'Home' },
    children: [
      {
        path: '/',
        redirect: '/me'
      },
      {
        path: 'me',
        component: Me,

        meta: {
          label: 'Профиль',
          requiresAuth: true
        }
      },
      {
        path: 'users',
        component: Users,

        meta: {
          label: 'Пользователи',
          requiresAuth: true,
          permission: 'users.read'
        }
      },
      {
        path: 'groups',
        component: Groups,

        meta: {
          label: 'Группы и разрешения',
          requiresAuth: true,
          permission: 'groups.read'
        }
      },
      ...prefixRoutes('/certs', [
        {
          path: '',
          redirect: '/certs/groups'
        },
        {
          path: 'groups',
          component: CertGroups,

          meta: {
            label: 'Сертификаты: Группы',
            requiresAuth: true,
            permission: 'certs.write'
          }
        },
        {
          path: 'view',
          component: Certs,

          meta: {
            label: 'Сертификаты: Просмотр',
            requiresAuth: true,
            permission: 'certs.read'
          }
        },
        {
          path: 'stats',

          meta: {
            label: 'Сертификаты: Статистика',
            requiresAuth: true,
            reload: true,
            permission: 'certs.stats'
          }
        }
      ]),
      ...prefixRoutes('/stocks', [
        {
          path: '',
          component: Stocks,

          meta: {
            label: 'Склады',
            requiresAuth: true,
            permission: 'inventory.read'
          }
        },
        {
          path: 'spares',
          component: StocksSpares,

          meta: {
            label: 'Склады: Запчасти',
            requiresAuth: true,
            searchable: true,
            permission: 'inventory.read'
          }
        },
        {
          path: 'inventory',
          component: StocksInventory,

          meta: {
            label: 'Склады: Инвентарь',
            requiresAuth: true,
            searchable: true,
            permission: 'inventory.read'
          }
        }
      ]),
      ...prefixRoutes('/vehicles', [
        {
          path: '',
          component: Vehicles,

          meta: {
            label: 'Техника',
            requiresAuth: true,
            permission: 'vehicles.read'
          }
        }
      ]),
      ...prefixRoutes('/schedule', [
        {
          path: 'points',
          component: SchedulePoints,

          meta: {
            label: 'Прокат: Точки',
            requiresAuth: true,
            permission: 'rp.read'
          }
        },
        {
          path: 'services',
          component: ScheduleServices,

          meta: {
            label: 'Прокат: Услуги',
            requiresAuth: true,
            permission: 'services.read'
          }
        },
        {
          path: 'calendar',
          component: ScheduleCalendar,

          meta: {
            label: 'Прокат: Календарь',
            requiresAuth: true,
            permission: 'schedule.read'
          }
        }
      ]),
      {
        path: 'journal',
        component: Journal,
        meta: {
          label: 'Системный журнал',
          requiresAuth: true,
          permission: 'journal.read'
        }
      },
      {
        path: '404',
        component: NotFound,
        meta: { label: '404' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (
    to.path !== '/login' &&
    !router.app.$store.state.token
  ) {
    const query = to.path !== '/404'
      ? { redirect: to.path.replace('/', '') }
      : undefined
    next({ path: '/login', query })
    return
  }

  if (
    to.meta.permission &&
    !router.app.$global.hasPermissions(to.meta.permission)
  ) {
    next('404')
    return
  }

  next()

  if (
    to.meta.reload &&
    !window.location.href.includes('localhost')
  ) {
    router.go()
  }
})

export default router
