var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-lte-aside",
    { attrs: { mini: "" } },
    [
      _c("a-lte-aside-brand", {
        attrs: { logo: "/images/logo.png", header: "Quadroclub" }
      }),
      _vm._v(" "),
      _c(
        "a-lte-aside-container",
        [
          _c("a-lte-aside-user", {
            attrs: {
              image: _vm.$global.userAvatar(_vm.user),
              username: _vm.user.fullName
            }
          }),
          _vm._v(" "),
          _c(
            "a-lte-aside-nav",
            { attrs: { legacy: "", flat: "" } },
            [
              _vm._l(_vm.menu, function(item) {
                return [
                  !item.children && !item.to
                    ? _c("a-lte-aside-nav-header", {
                        key: item.title,
                        attrs: { title: item.title }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !item.children && item.to
                    ? _c("a-lte-aside-nav-item", {
                        key: item.title,
                        attrs: {
                          title: item.title,
                          icon: item.icon,
                          badge: item.badge,
                          "badge-variant": item.badgeVariant,
                          to: item.to
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.children
                    ? _c(
                        "a-lte-aside-nav-item-dropdown",
                        {
                          key: item.title,
                          attrs: {
                            title: item.title,
                            badge: item.badge,
                            "badge-variant": item.badgeVariant,
                            icon: item.icon
                          }
                        },
                        _vm._l(item.children, function(submenu) {
                          return _c("a-lte-aside-nav-item", {
                            key: submenu.title,
                            attrs: {
                              title: submenu.title,
                              icon: submenu.icon,
                              badge: submenu.badge,
                              "badge-variant": submenu.badgeVariant,
                              to: submenu.to
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }