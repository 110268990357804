var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.control
        ? _c(
            "b-row",
            { staticClass: "mb-2 justify-content-center" },
            [
              _c(
                "b-col",
                { attrs: { sm: "3" } },
                [
                  _c("b-pagination", {
                    staticClass: "my-0",
                    attrs: {
                      "per-page": _vm.perPage,
                      "total-rows": _vm.items.length,
                      align: "fill",
                      size: "sm"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "1" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "number", size: "sm", min: "10", max: "50" },
                    model: {
                      value: _vm.perPage,
                      callback: function($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "mb-2", attrs: { sm: "2" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.modulesOptions,
                      label: "Модуль",
                      size: "sm"
                    },
                    model: {
                      value: _vm.byModule,
                      callback: function($$v) {
                        _vm.byModule = $$v
                      },
                      expression: "byModule"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "sm-12" },
        [
          _c("b-table", {
            ref: "table",
            staticClass: "text-center",
            attrs: {
              busy: _vm.loading,
              items: _vm.items,
              fields: _vm.fields,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              filter: _vm.byModule,
              "filter-included-fields": ["module", "type"],
              "sort-desc": true,
              "sort-by": "createdAt",
              "head-variant": "light",
              hover: "",
              small: "",
              outlined: "",
              bordered: "",
              responsive: "sm"
            },
            on: {
              "row-clicked": function(item) {
                return _vm.rowClicked(item)
              } /* item=>$set(item, '_showDetails', !item._showDetails) */
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Загрузка...")])
                          ],
                          1
                        )
                      : _vm.error
                        ? _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [_c("strong", [_vm._v("Ошибка!...")])]
                          )
                        : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }