<template>
  <a-lte-aside mini>
    <a-lte-aside-brand logo="/images/logo.png" header="Quadroclub" />

    <a-lte-aside-container>
      <a-lte-aside-user
        :image="$global.userAvatar(user)"
        :username="user.fullName"
      />
      <a-lte-aside-nav legacy flat>
        <template v-for="item in menu">
          <a-lte-aside-nav-header
            v-if="!item.children && !item.to"
            :title="item.title"
            :key="item.title"
          />
          <a-lte-aside-nav-item
            v-if="!item.children && item.to"
            :title="item.title"
            :key="item.title"
            :icon="item.icon"
            :badge="item.badge"
            :badge-variant="item.badgeVariant"
            :to="item.to"
          />
          <a-lte-aside-nav-item-dropdown
            v-if="item.children"
            :key="item.title"
            :title="item.title"
            :badge="item.badge"
            :badge-variant="item.badgeVariant"
            :icon="item.icon"
          >
            <a-lte-aside-nav-item
              v-for="submenu in item.children"
              :key="submenu.title"
              :title="submenu.title"
              :icon="submenu.icon"
              :badge="submenu.badge"
              :badge-variant="submenu.badgeVariant"
              :to="submenu.to"
            />
          </a-lte-aside-nav-item-dropdown>
        </template>
      </a-lte-aside-nav>
    </a-lte-aside-container>
  </a-lte-aside>
</template>

<script>
const menuData = [
  {
    title: 'Профиль',
    icon: 'user',
    to: '/me'
  },
  {
    title: 'Пользователи',
    icon: 'users',
    to: '/users',
    requires: 'users.read',
    header: { title: 'УПРАВЛЕНИЕ' },

    children: [
      {
        title: 'Просмотр',
        icon: 'search',
        to: '/users',
        requires: 'users.read'
      },
      {
        title: 'Группы и разрешения',
        icon: 'user-shield',
        to: '/groups',
        requires: 'groups.read'
      }
    ]
  },
  {
    title: 'Сертификаты',
    icon: 'certificate',
    badgeVariant: 'success',
    requires: 'certs.read',

    children: [
      {
        title: 'Группы',
        icon: 'th-list',
        to: '/certs/groups',
        requires: 'certs.write'
      },
      {
        title: 'Просмотр',
        icon: 'search',
        to: '/certs/view',
        requires: 'certs.read'
      },
      {
        title: 'Статистика',
        icon: 'chart-bar',
        to: '/certs/stats',
        requires: 'certs.stats'
      }
    ]
  },
  {
    title: 'Склады',
    icon: 'boxes',
    badgeVariant: 'success',
    requires: 'inventory.read',

    children: [
      {
        title: 'Просмотр',
        icon: 'search',
        to: '/stocks',
        requires: 'inventory.read'
      },
      {
        title: 'Запчасти',
        icon: 'wrench',
        to: '/stocks/spares',
        requires: 'inventory.read'
      },
      {
        title: 'Инвентарь',
        icon: 'briefcase',
        to: '/stocks/inventory',
        requires: 'inventory.read'
      }
    ]
  },
  {
    title: 'Техника',
    icon: 'motorcycle',
    badgeVariant: 'success',
    requires: 'vehicles.read',

    children: [
      {
        title: 'Просмотр',
        icon: 'search',
        to: '/vehicles',
        requires: 'vehicles.read'
      },
      {
        title: 'Трекеры',
        icon: 'compass',
        to: '/vehicles/trackers',
        requires: 'vehicles.read',
        badge: 'В разработке'
      }
    ]
  },
  {
    title: 'Прокат',
    icon: 'road',
    badgeVariant: 'success',
    requires: 'vehicles.read',

    children: [
      {
        title: 'Точки',
        icon: 'map-marker-alt',
        to: '/schedule/points',
        requires: 'rp.read'
      },
      {
        title: 'Услуги',
        icon: 'male',
        to: '/schedule/services',
        requires: 'services.read'
      },
      {
        title: 'Календарь',
        icon: 'calendar',
        to: '/schedule/calendar',
        requires: 'schedule.read'
      },
      {
        title: 'Статистика',
        icon: 'chart-bar',
        to: '/schedule/stats',
        requires: 'schedule.read',
        badge: 'В разработке'
      }
    ]
  },
  {
    title: 'Системный журнал',
    icon: 'history',
    to: '/journal',
    requires: 'journal.read'
  }
]
export default {
  data() {
    return {
      user: this.$store.state.user,
      menu: []
    }
  },
  mounted() {
    menuData.forEach(entry => {
      if (!entry.requires) {
        this.menu.push(entry)
        return
      }
      if (this.$global.hasPermissions(entry.requires)) {
        if ('header' in entry) {
          this.menu.push(entry.header)
        }

        if (entry.children) {
          entry.children = entry.children.filter(e =>
            this.$global.hasPermissions(e.requires))
        }

        this.menu.push(entry)
      }
    })
  }
}
</script>
