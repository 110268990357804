var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.toggle,
          expression: "toggle"
        }
      ],
      class: [
        "main-sidebar",
        "sidebar-" + _vm.type + "-" + _vm.variant,
        _vm.elevationClass
      ]
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }