var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "nav",
    _vm._g(_vm._b({}, "nav", _vm.data.attrs, false), _vm.listeners),
    [
      _c(
        "ul",
        {
          class: {
            nav: true,
            "nav-sidebar": true,
            "nav-pills": true,
            "flex-column": true,
            "nav-flat": _vm.props.flat,
            "nav-legacy": _vm.props.legacy,
            "nav-compact": _vm.props.compact,
            "nav-child-indent": _vm.props.childIndent
          },
          staticStyle: { overflow: "hidden" },
          attrs: { "data-widget": "treeview", role: "menu" }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }