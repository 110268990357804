var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    ref: "table",
    staticClass: "text-center",
    attrs: {
      busy: _vm.loading,
      items: _vm.items,
      fields: _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "tbody-tr-class": _vm.rowClass,
      "head-variant": "light",
      bordered: "",
      hover: "",
      responsive: "sm"
    },
    on: {
      "update:sortBy": function($event) {
        _vm.sortBy = $event
      },
      "update:sort-by": function($event) {
        _vm.sortBy = $event
      },
      "update:sortDesc": function($event) {
        _vm.sortDesc = $event
      },
      "update:sort-desc": function($event) {
        _vm.sortDesc = $event
      },
      "row-clicked": _vm.toggleDetails
    },
    scopedSlots: _vm._u(
      [
        {
          key: "table-busy",
          fn: function() {
            return [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(_vm.$t("loading")))])
                    ],
                    1
                  )
                : _vm.error
                  ? _c("div", { staticClass: "text-center text-danger my-2" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("error")))])
                    ])
                  : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "top-row",
          fn: function(ref) {
            var fields = ref.fields
            return _vm.topRow
              ? _vm._l(fields, function(field, key) {
                  return _c(
                    "b-td",
                    { key: key },
                    [
                      field.editable || field.editableTop
                        ? [
                            _c(
                              "b-col",
                              [
                                field.selectOptions
                                  ? _c("b-form-select", {
                                      attrs: {
                                        options: field.selectOptions(),
                                        size: "sm"
                                      },
                                      model: {
                                        value: _vm.newItem[field.key],
                                        callback: function($$v) {
                                          _vm.$set(_vm.newItem, field.key, $$v)
                                        },
                                        expression: "newItem[field.key]"
                                      }
                                    })
                                  : field.isDate
                                    ? _c("b-form-datepicker", {
                                        attrs: {
                                          state: field.validator(_vm.newItem),
                                          "date-format-options": {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric"
                                          },
                                          min: new Date(),
                                          size: "sm",
                                          placeholder: "Дата",
                                          "label-help": ""
                                        },
                                        model: {
                                          value: _vm.newItem[field.key],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newItem,
                                              field.key,
                                              $$v
                                            )
                                          },
                                          expression: "newItem[field.key]"
                                        }
                                      })
                                    : _c("b-form-input", {
                                        staticClass: "text-center",
                                        attrs: {
                                          state: field.validator(_vm.newItem),
                                          type: field.input.type || "text",
                                          min: field.input.min,
                                          max: field.input.max,
                                          size: "sm"
                                        },
                                        on: {
                                          focus: function($event) {
                                            field._focus = true
                                          },
                                          blur: function($event) {
                                            field._focus = !!_vm.newItem[
                                              field.key
                                            ]
                                          }
                                        },
                                        model: {
                                          value: _vm.newItem[field.key],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newItem,
                                              field.key,
                                              $$v
                                            )
                                          },
                                          expression: "newItem[field.key]"
                                        }
                                      })
                              ],
                              1
                            )
                          ]
                        : field.key === "actions"
                          ? _c("b-col", [_vm._t("top-actions")], 2)
                          : _c("span", [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    field.formatter(_vm.newItem[field.key])
                                  ) +
                                  "\n      "
                              )
                            ])
                    ],
                    2
                  )
                })
              : undefined
          }
        },
        {
          key: "cell()",
          fn: function(row) {
            return [
              !row.item._edit || !row.field.editable
                ? _c("span", [
                    _vm._v("\n      " + _vm._s(row.value) + "\n    ")
                  ])
                : _c(
                    "b-col",
                    [
                      row.field.selectOptions
                        ? _c("b-form-select", {
                            attrs: {
                              options: row.field.selectOptions(),
                              disabled: row.field.disabled(row.item),
                              size: "sm"
                            },
                            model: {
                              value: row.item[row.field.key],
                              callback: function($$v) {
                                _vm.$set(row.item, row.field.key, $$v)
                              },
                              expression: "row.item[row.field.key]"
                            }
                          })
                        : row.field.isDate
                          ? _c("b-form-datepicker", {
                              attrs: {
                                state: row.field.validator(row.item),
                                "date-format-options": {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric"
                                },
                                min: new Date(),
                                size: "sm",
                                placeholder: "Дата",
                                "label-help": ""
                              },
                              model: {
                                value: row.item[row.field.key],
                                callback: function($$v) {
                                  _vm.$set(row.item, row.field.key, $$v)
                                },
                                expression: "row.item[row.field.key]"
                              }
                            })
                          : _c("b-form-input", {
                              staticClass: "text-center",
                              attrs: {
                                state: row.field.validator(row.item),
                                min: row.field.input.min,
                                max: row.field.input.max,
                                size: "sm"
                              },
                              model: {
                                value: row.item[row.field.key],
                                callback: function($$v) {
                                  _vm.$set(row.item, row.field.key, $$v)
                                },
                                expression: "row.item[row.field.key]"
                              }
                            })
                    ],
                    1
                  )
            ]
          }
        },
        _vm._l(_vm.cellSlots, function(slot) {
          return {
            key: slot,
            fn: function(row) {
              return [_vm._t(slot, null, null, row)]
            }
          }
        }),
        {
          key: "row-details",
          fn: function(row) {
            return [_vm._t("row-details", null, null, row)]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }