var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          staticClass:
            "overlay d-flex justify-content-center align-items-center"
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }