import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function flattenObject(obj, parent = '') {
  let newObj = {}
  Object.entries(obj).forEach(([key, value]) => {
    const parentKey = parent ? `${parent}.${key}` : key
    if (value instanceof Object) {
      newObj = {
        ...newObj,
        ...flattenObject(value, parentKey)
      }
    } else {
      newObj[parentKey] = value
    }
  })
  return newObj
}

// eslint-disable-next-line func-names
VueI18n.prototype.tf = function (key, arg) {
  return this.t(key, flattenObject(arg))
}

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru'
})
