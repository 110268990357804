var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-lte-layout-page",
    [
      _c("app-header"),
      _vm._v(" "),
      _c("app-aside"),
      _vm._v(" "),
      _c(
        "a-lte-content",
        [
          _c(
            "b-container",
            { attrs: { slot: "header", fluid: "" }, slot: "header" },
            [
              _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c("b-col", { attrs: { sm: "6" } }, [
                    _c("h1", { staticClass: "m-0 text-dark" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      attrs: { sm: "6" }
                    },
                    [
                      _c("a-lte-breadcrumbs", {
                        attrs: {
                          list: _vm.breadcrumbs,
                          transition: "breadcrumbs"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [_c("router-view")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("app-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }