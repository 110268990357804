<template>
  <b-card class="sm-12">
    <q-table
      ref="table"
      :loading="loading"
      :error="error"
      :items="items"
      :fields="fields"
      :new-item="newItem"
      :row-clickable="false"
      @toggle-edit="toggleEdit"
    >
      <template slot="top-actions">
        <b-btn variant="success" @click="createItem" >
          <font-awesome-icon icon="user-shield" />
          {{ $t('button.create') }}
        </b-btn>
      </template>

      <template v-slot:cell(actions)="row">
        <b-btn
          v-if="!row.item._edit"
          :disabled="isDisabled(row.item)"
          variant="info"
          @click="$refs.table.toggleEdit(row.item)"
        >
          <font-awesome-icon icon="edit" />
        </b-btn>
        <b-btn
          v-else
          :disabled="isDisabled(row.item)"
          variant="success"
          @click="confirmEdit(row.item, row.index)"
        >
          <font-awesome-icon icon="check" />
        </b-btn>
        <b-btn
          :disabled="isDisabled(row.item)"
          variant="danger"
          @click="deleteItem(row.item)"
        >
          <font-awesome-icon icon="times" />
        </b-btn>
      </template>

      <template slot="row-details" slot-scope="row">
        <div class="text-left">
          <b-card
            v-for="(permsGroup, key) in permissions"
            :key="key"
            no-body
            class="mt-2"
          >
            <b-card-header>
              <b-card-title>
                <b-row>
                  <b-checkbox
                    :disabled="isDisabled(row.item)"
                    :checked="row.item.id === 1 || permissionsState(row.item, key) === 1"
                    :indeterminate="permissionsState(row.item, key) === -1"
                    class="ml-2"
                    @change="(checked) => toggleAll(row.item, key, checked)"
                  />
                  <a v-b-toggle="row.item.name + key" :href="`#${key}`">
                    {{ $t(`translate.${key}`) }}
                  </a>
                </b-row>
              </b-card-title>
            </b-card-header>
            <b-collapse
              :id="row.item.name + key"
              :ref="key"
            >
              <b-card-body>
                <b-checkbox
                  v-for="perm in permsGroup"
                  v-model="row.item.permissions"
                  :key="perm"
                  :value="row.item.id === 1 ? '*' : `${key}.${perm}`"
                  :disabled="isDisabled(row.item)"
                  switch
                >
                  <b-card-text class="font-weight-normal">
                    {{ $t(`permission.${key}.${perm}`) }}
                  </b-card-text>
                </b-checkbox>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </template>
    </q-table>
  </b-card>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],

      newItem: {},
      lastItem: null,
      lastIndex: 0,

      permissions: {},

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'name',
          label: this.$t('field.name'),
          sortable: true,
          editable: true,
          validator: v => !!v
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  async mounted() {
    try {
      const { data: permsData } = await this.$api.permissions.list()
      const { data: groupsData } = await this.$api.groups.list()

      this.permissions = permsData.permissions
      this.items = groupsData.groups

      this.$refs.table.sort('id', false)
      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        name: '',
        permissions: []
      }
    },
    isDisabled(item) {
      return item.locked || !this.$global.hasPermissions('groups.write')
    },
    toggleDetails(item) {
      this.$set(item, '_edit', !item._edit)
      this.$set(item, '_showDetails', !item._showDetails)
    },
    async toggleEdit(item) {
      // const index = this.items.indexOf(item)
      // console.log(index, item, this.lastItem)

      if (!item._skip && this.lastItem && this.lastItem._edit) {
        await this.confirmEdit(this.lastItem, this.lastIndex)
      }

      item._skip = false
      this.lastItem = item
      this.lastIndex = this.items.indexOf(item)
      this.$set(item, '_showDetails', !item._showDetails)
    },

    permissionsState(item, key) {
      const count = item.permissions
        .filter(v => v.startsWith(key))
        .length

      if (count === 0) return 0
      if (count === this.permissions[key].length) return 1
      return -1 // indeterminate
    },
    toggleAll(item, key, checked) {
      const perms = this.permissions[key].map(v => `${key}.${v}`)
      if (checked) {
        perms.forEach(v => {
          if (item.permissions.indexOf(v) === -1) {
            item.permissions.push(v)
          }
        })
      } else {
        item.permissions = item.permissions.filter(v => !perms.includes(v))
      }
    },

    async createItem() {
      if (!this.$refs.table.isFieldsValid(this.newItem)) {
        this.$global.toast('dataInvalid')
        return
      }

      try {
        this.loading = true
        const { data } = await this.$api.groups.create(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id
        this.toggleDetails(item)

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('group.created', 'successInfo')
        this.$refs.table.sort('id', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (this.$refs.table.isItemCached(item)) {
        item._skip = true
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        item._skip = true
        this.$refs.table.toggleEdit(item)
        this.$refs.table.restoreItem(index)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        await this.$api.groups.update(item)
        this.$global.toast('group.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        item._skip = true
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    deleteItem(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.groups.delete(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('group.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  create: Создать
field:
  createdAt: Создан
  name: Группа
  actions: Действия
translate:
  users: Пользователи
  groups: Группы и разрешения
  certs: Сертификаты
  inventory: Склады и инвентарь
  vehicles: Техника
  services: Услуги
  rentalPoints: Точки проката
  schedule: Календарь
  journal: Системный журнал
permission:
  users:
    read: Просмотр пользователей
    write: Создание пользователей
    delete: Удаление пользователей
  groups:
    read: Просмотр групп
    write: Редактирование групп
    delete: Удаление групп
  certs:
    read: Просмотр сертификатов
    write: Генерация и изменение сертификатов
    delete: Удаление сертификатов
    readSecret: Просмотр секретного кода
    transfer: Передача сертификатов
    sell: Продажа сертификатов
    activate: Активация сертификатов
    export: Экспорт сертификатов
    stats: Статистика сертификатов
    sellBackdating: Продажа задним числом
    activateBackdating: Активация задним числом
    readAll: Доступ ко всем сертификатам
  inventory:
    read: Просмотр складов и инвентаря
    write: Изменение складов и инвентаря
    delete: Удаление складов и инвентаря
  vehicles:
    read: Просмотр техники
    write: Изменение техники
    delete: Удаление техники
  services:
    read: Просмотр услуг
    write: Изменение услуг
    delete: Удаление услуг
  rentalPoints:
    read: Просмотр точек проката
    write: Изменение точек проката
    delete: Удаление точек проката
  schedule:
    read: Просмотр календаря
    write: Изменение календаря
    delete: Удаление календаря
  journal:
    read: Просмотр журнала
modal:
  delete:
    message: |
      Вы уверены, что хотите удалить эту группу?
      Её пользователи перейдут в ограниченную группу.
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
</i18n>
