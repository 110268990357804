<template>
  <a-lte-footer>
    Copyright © <strong>LLC Quadroclub 2021</strong>. All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <strong>
        {{ version }}
        <!-- <a class="ml-1" href="/docs">{...}</a> -->
      </strong>
    </div>
  </a-lte-footer>
</template>

<script>
import npmPackage from '@/../../package.json'

export default {
  data() {
    return {
      version: npmPackage.version
    }
  }
}
</script>
