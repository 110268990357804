<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
        :row-clickable="false"
      >
        <template slot="top-actions">
          <b-btn variant="success" @click="createStock">
            <font-awesome-icon icon="plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template v-slot:cell(description)="row">
          <span v-if="row.item.description && !row.item._edit">
            {{ row.item.description }}
          </span>
          <b-form-input
            v-else-if="row.item._edit"
            v-model="row.item.description"
            class="text-center"
            size="sm"
          />
          <i v-else>{{ $t('empty') }}</i>
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.edit')"
            variant="info"
            @click="$refs.table.toggleEdit(row.item)"
          >
            <font-awesome-icon icon="edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="confirmEdit(row.item, row.index)"
          >
            <font-awesome-icon icon="check" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :title="$t('button.delete')"
            variant="danger"
            @click="deleteStock(row.item)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
        </template>
      </q-table>
    </b-card>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],
      newItem: {},

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'id',
          label: this.$t('field.id'),
          sortable: true
        },
        {
          key: 'name',
          label: this.$t('field.name'),
          sortable: true,
          editable: true,
          thStyle: { width: '200px' }
        },
        {
          key: 'description',
          label: this.$t('field.description'),
          sortable: true,
          editable: true,
          formatter: v => v || this.$t('empty')
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  async mounted() {
    try {
      const { data } = await this.$api.stocks.list()
      this.items = data.stocks || []
      this.$refs.table.sortBy = 'id'
      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        name: '',
        description: ''
      }
    },

    async createStock() {
      try {
        this.loading = true
        const { data } = await this.$api.stocks.create(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('stock.created', 'successInfo')
        this.$refs.table.sort('createdAt', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        this.$refs.table.restoreItem(index)
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      if (this.$refs.table.isItemCached(item)) {
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        await this.$api.stocks.update(item)
        this.$global.toast('stock.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    async deleteStock(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.stocks.delete(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('stock.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  create: Создать
  edit: Редактировать
  confirm: Подтвердить
  delete: Удалить
field:
  createdAt: Создан
  id: Номер
  name: Название
  description: Описание
  actions: Действия
modal:
  delete:
    message: Вы уверены, что хотите удалить этого пользователя?
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
empty: Пусто
</i18n>
