var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "justify-content-center" },
    [
      _c(
        "b-col",
        { attrs: { sm: "12", xl: "12" } },
        [
          _c(
            "a-lte-card-widget-user",
            {
              attrs: {
                username: _vm.user.fullName,
                desc: _vm.user.group.name,
                avatar: _vm.$global.userAvatar(_vm.user)
              },
              on: { upload: _vm.uploadAvatar }
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "border-right", attrs: { sm4: "" } },
                    [
                      _c(
                        "a-lte-description-block",
                        { attrs: { header: _vm.$t("header.email") } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.user.email) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm4: "" } },
                    [
                      _c(
                        "a-lte-description-block",
                        { attrs: { header: _vm.$t("header.phone") } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.user.phone) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "border-left", attrs: { sm4: "" } },
                    [
                      _c(
                        "a-lte-description-block",
                        { attrs: { header: _vm.$t("header.rentalPoints") } },
                        [
                          _vm._l(_vm.user.rentalPoints, function(rp) {
                            return [
                              _c(
                                "span",
                                {
                                  key: rp.id,
                                  staticStyle: { "white-space": "pre" }
                                },
                                [_vm._v(_vm._s(rp.name)), _c("br")]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showJournal
        ? _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "h2",
                {
                  staticClass: "m-0 mb-2 mt-2 text-dark",
                  staticStyle: { "font-size": "1.8rem" }
                },
                [_vm._v("Журнал действий")]
              ),
              _vm._v(" "),
              _c("Journal", {
                ref: "journal",
                attrs: { control: false, "user-id": _vm.$store.state.user.id }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }