<template>
  <div>
    <b-row class="mb-4 justify-content-center">
      <b-col class="pl-3" sm="3">
        <b-pagination
          v-model="currentPage"
          :disabled="showSelectedRows"
          :per-page="perPage"
          :total-rows="totalCount"
          first-number
          last-number
          align="fill"
          size="sm"
          class="my-0"
          @change="fetchCerts"
        />
      </b-col>

      <b-col class="pr-1" sm="1">
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-input-group-text>ID</b-input-group-text>
          </template>
          <b-form-input
            :min="1"
            type="number"
            @change="filterCert"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col class="pl-1" sm="3">
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-input-group-text>{{ $t('label.checked') }}</b-input-group-text>
          </template>
          <b-form-input :value="rangeString" disabled/>
          <template v-slot:append>
            <b-input-group-text>
              <b-checkbox
                v-model="showSelectedRows"
                :disabled="selectedRows.length === 0"
                switch
                class="mr-n2 mb-n1"
                size="sm"
              />
            </b-input-group-text>
          </template>
        </b-input-group>
      </b-col>

      <b-col class="pr-1" sm="1">
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-input-group-text>{{ $t('label.from') }}</b-input-group-text>
          </template>
          <b-form-input
            v-model="range.from"
            :min="0"
            :max="Math.min(range.to, currentPage * perPage)"
            :disabled="showSelectedRows"
            type="number"
            @change="updateMinMaxRange"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col class="pl-1" sm="2">
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-input-group-text>{{ $t('label.to') }}</b-input-group-text>
          </template>
          <b-form-input
            v-model="range.to"
            :min="range.from"
            :max="currentPage * perPage"
            :disabled="showSelectedRows"
            type="number"
            @change="updateMinMaxRange"
          ></b-form-input>
          <template v-slot:append>
            <b-dropdown size="sm" text="действия">
              <b-dropdown-item-button
                :disabled="isDisabled('instock')"
                @click="instockCerts"
              >{{ $t('button.instock') }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                :disabled="isDisabled('transfer')"
                @click="openModalTransfer"
              >{{ $t('button.transfer') }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                :disabled="isDisabled('sell')"
                @click="openModalBackdating(sellCerts)"
              >{{ $t('button.sell') }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                :disabled="isDisabled('activate')"
                @click="openModalBackdating(activateCerts)"
              >{{ $t('button.activate') }}
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-input-group>
      </b-col>
    </b-row>

    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :top-row="false"
      >
        <template slot="row-details" slot-scope="row">
          <b-row>
            <b-col
              v-for="value in [{
                key: 'owner',
                user: row.item.owner,
                date: row.item.createdAt
              },{
                key: 'soldBy',
                user: row.item.soldBy,
                date: row.item.soldAt
              },{
                key: 'activatedBy',
                user: row.item.activatedBy,
                date: row.item.activatedAt
              }]"
              :key="value.key"
              sm4
            >
              <a-lte-description-block :header="$t(`field.${value.key}`)">
                <template v-if="value.user">
                  <template
                    v-if="value.key === 'owner' && row.item.transferTo
                    && row.item.transferTo.id !== row.item.owner.id"
                  >
                    <span v-html="$i18n.tf('state.transfers', row.item)" />
                  </template>
                  <template v-else>
                    <span>{{ value.user.fullName }}</span>
                    (<span>{{ new Date(value.date).toLocaleDateString() }}</span>)
                  </template>
                </template>
                <span v-else>{{ $t('label.not') }} {{ $t(`field.${value.key}`).toLowerCase() }}</span>
              </a-lte-description-block>
            </b-col>
          </b-row>
        </template>

        <template v-slot:cell(checked)="row">
          <b-form-checkbox
            v-model="selectedRows"
            :value="row.item.id"
            @change="v => updateRange(!!v, row.item.id)"
          >
            {{ row.value }}
          </b-form-checkbox>
        </template>
        <template v-slot:cell(actions)="row">
          <span
            v-if="row.item.secret"
            class="mr-2"
          >
            {{ row.item.secret }}
          </span>
          <b-btn
            class="mr-2"
            variant="warning"
            @click="readSecret(row.item)"
          >
            <template v-if="!row.item.secretTimer">
              <font-awesome-icon icon="lock" />
              {{ $t('button.secret') }}
            </template>
            <template v-else>
              {{ row.item.secretTimer }}
            </template>
          </b-btn>

          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.extend')"
            variant="info"
            @click="toggleEdit(row.item)"
          >
            <font-awesome-icon icon="calendar-plus" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="extendCert(row.item)"
          >
            <font-awesome-icon icon="calendar-plus" />
          </b-btn>

          <b-btn
            v-b-tooltip.hover
            v-if="row.item.state !== 'banned'"
            :title="$t('button.ban')"
            variant="danger"
            @click="banCert(row.item, true)"
          >
            <font-awesome-icon icon="times" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.unban')"
            variant="success"
            @click="banCert(row.item, false)"
          >
            <font-awesome-icon icon="unlock" />
          </b-btn>
        </template>
      </q-table>
    </b-card>

    <b-modal
      id="modal-transfer"
      v-model="modalTransfer.show"
      :title="modalTransfer.title"
      :ok-title="$t('modal.transfer.ok')"
      :cancel-title="$t('modal.transfer.cancel')"
      @ok="transferCerts"
      @hidden="resetModalTransfer"
    >
      <p v-html="$t('modal.transfer.message')"></p>
      <b-form-select
        v-model="modalTransfer.transferTo"
        :options="modalTransfer.options"
      />
    </b-modal>
    <b-modal
      id="modal-backdating"
      v-model="modalBackdating.show"
      :title="modalBackdating.title"
      :ok-title="$t('modal.backdating.ok')"
      :cancel-title="$t('modal.backdating.cancel')"
      @ok="modalBackdating.action"
      @hidden="resetModalBackdating"
    >
      <p v-html="$t('modal.backdating.message')"></p>
      <b-datepicker
        v-model="modalBackdating.date"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :min="modalBackdating.min"
        :max="new Date()"
        size="sm"
        placeholder="Дата"
        label-help=""
      />
    </b-modal>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],

      currentPage: 1,
      perPage: 100,
      totalCount: 100,

      showSelectedRows: false,
      selectedRows: [],
      selectedItems: [],

      range: {
        from: 0,
        to: 0
      },

      backdating: {
        canSell: this.$global.hasPermissions('certs.sellBackdating'),
        canActivate: this.$global.hasPermissions('certs.activateBackdating')
      },

      modalTransfer: {
        show: false,
        title: '',
        transferTo: null,
        options: []
      },
      modalBackdating: {
        show: false,
        title: '',
        min: undefined,
        date: null,
        action: () => {}
      },

      fields: [
        {
          key: 'checked',
          label: ''
        },
        {
          key: 'id',
          label: this.$t('field.id')
        },
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleDateString()
        },
        {
          key: 'expiresAt',
          label: this.$t('field.expiresAt'),
          isDate: true,
          sortable: true,
          editable: true,

          formatter: v => new Date(v).toLocaleDateString(),
          validator: v => !!v,

          tdClass: (v, key, item) => (this.isExpired(item) ? 'text-danger text-bold' : '')
        },
        {
          key: 'cost',
          label: this.$t('field.cost'),
          sortable: true
        },
        {
          key: 'state',
          label: this.$t('field.state'),
          sortable: true,
          formatter: v => this.$t(`state.${v}`)
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  computed: {
    rangeString() {
      return this.$global.rangeString(this.selectedRows)
    }
  },
  watch: {
    async showSelectedRows(show) {
      this.loading = true
      if (show) {
        this.items = this.selectedItems
      } else {
        await this.fetchCerts(this.currentPage)
      }
      this.loading = false
    },
    async selectedRows(rows) {
      if (!rows) return

      if (rows.length === 0) {
        if (this.showSelectedRows) {
          this.showSelectedRows = false
        }
        return
      }

      this.loading = true
      try {
        const { data: { certs } } = await this.$api.certs.get(rows)
        this.selectedItems = certs
        this.loading = false
      } catch (e) {
        this.error = true
      }
    }
  },
  async mounted() {
    await this.fetchCerts(1)
    const hp = this.$global.hasPermissions

    this.modalTransfer.options = [{
      value: null,
      text: this.$t('modal.transfer.defaultSelect')
    }]

    if (hp('users.read') && hp('certs.transfer')) {
      try {
        const { data: { users } } = await this.$api.users.list()
        const { data: { groups } } = await this.$api.groups.list()

        const findGroup = v => groups.filter(g => g.id === v.group.id)[0]
        const options = users
          .filter(v => v.id !== this.$store.state.user.id)
          .filter(v => hp('certs.read', findGroup(v).permissions))
          .map(v => ({ value: v, text: v.fullName }))

        this.modalTransfer.options.push(...options)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row' && this.isExpired(item)) {
        return 'table-warning'
      }
      return ''
    },
    pageCount() {
      return this.currentPage * this.perPage
    },

    isDisabled(action) {
      if (!this.selectedItems || !this.selectedItems.length) return true

      const some = fn => this.selectedItems.some(v => fn(v))
      const each = fn => this.selectedItems.every(v => fn(v))

      const requiredStates = {
        instock: 'ready',
        transfer: 'instock',
        sell: 'ready',
        activate: 'sold'
      }

      // only super admin can ban cert
      // only owner can manage cert
      // actions must be disabled if one of certs is banned
      // you can't transfer already transferring certs
      // you can't transfer sold cert
      // you can't sell activated cert

      if (action === 'instock' && each(item => !!item.transferTo)) {
        return false
      }

      return (!this.$global.isSuperAdmin()
        && some(item => item.owner.id !== this.$store.state.user.id))
        || (action === 'transfer' && some(item => !!item.transferTo))
        || some(item => item.state === 'banned')
        || some(item => item.state !== requiredStates[action])
    },
    isExpired(item) {
      return new Date(item.expiresAt) <= new Date()
    },

    forEachItem(fn) {
      this.items.filter(v => this.selectedRows.includes(v.id)).forEach(fn)
      this.selectedItems.forEach(fn)
    },
    updateRange(checked, id) {
      let selected = [...new Set(this.selectedRows)]
      if (checked) {
        selected.push(id)
      } else {
        selected = selected.filter(v => v !== id)
      }

      this.range.from = Math.min(...selected)
      this.range.to = Math.max(...selected)
    },
    updateMinMaxRange() {
      const min = this.selectedRows.length ? Math.min(...this.selectedRows) : 0
      const max = this.selectedRows.length ? Math.max(...this.selectedRows) : 0

      this.range.from = parseInt(this.range.from, 10)
      this.range.to = parseInt(this.range.to, 10)

      if (this.range.from < 0) {
        this.range.from = 0
      } else if (this.range.from > this.pageCount()) {
        this.range.from = this.pageCount()
      } else if (this.range.to > this.pageCount()) {
        this.range.to = this.pageCount()
      }

      if (this.range.from > this.range.to) {
        this.range.to = this.range.from
      }

      if (min === 0) {
        this.range.to = this.range.from
        this.selectedRows.push(this.range.from)
      } else if (max === 0) {
        this.range.from = this.range.to
        this.selectedRows.push(this.range.to)
      } else if (this.range.from < min) {
        for (let i = this.range.from; i <= min; i++) {
          this.selectedRows.push(i)
        }
      } else if (this.range.to > max) {
        for (let i = max; i <= this.range.to; i++) {
          this.selectedRows.push(i)
        }
      } else if (this.range.from > min || this.range.from === 0) {
        for (let i = min; i < this.range.from; i++) {
          this.selectedRows = this.selectedRows.filter(v => v !== i)
        }
      } else if (this.range.to < max) {
        for (let i = this.range.to + 1; i <= max; i++) {
          this.selectedRows = this.selectedRows.filter(v => v !== i)
        }
      }

      // lazy filtering
      this.selectedRows = this.selectedRows
        .filter(v => v !== 0)
        .filter((v, i, arr) => i === arr.indexOf(v))
    },
    isRangeValid() {
      return this.range.from !== 0 && this.range.to !== 0
    },

    openModalTransfer() {
      if (!this.isRangeValid()) return
      this.$set(this.modalTransfer, 'show', true)
      this.modalTransfer.title = this.$t('modal.transfer.title', [this.rangeString])
    },
    resetModalTransfer() {
      this.modalTransfer.transferTo = null
    },
    async transferCerts() { // calls from modal
      this.loading = true
      try {
        const transferTo = this.modalTransfer.transferTo || this.$store.state.user
        await this.$api.certs.transfer(this.selectedRows, transferTo.id)

        if (!this.modalTransfer.transferTo) {
          this.forEachItem(v => { v.state = 'ready' })
        }

        this.forEachItem(v => { v.transferTo = transferTo })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },

    async filterCert(value) {
      const id = parseInt(value, 10)
      if (value <= 0 || value > this.totalCount) return

      if (!this.selectedRows.includes(id)) {
        this.selectedRows.push(id)
        this.updateRange(true, id)

        // накостылил...
        this.loading = true
        setTimeout(() => {
          if (!this.showSelectedRows) {
            this.showSelectedRows = true
          } else {
            this.items = this.selectedItems
            this.loading = false
          }
        }, 200)
      }
    },
    async fetchCerts(page) {
      this.loading = true

      try {
        const offset = (page - 1) * this.perPage
        const { data } = await this.$api.certs.list(offset, this.perPage)

        this.items = data.certs
        this.totalCount = data.totalCount
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    async instockCerts() {
      try {
        this.loading = true
        await this.$api.certs.instock(this.selectedRows)
        this.forEachItem(v => {
          v.state = 'instock'
          v.transferTo = null
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async openModalBackdating(action) {
      if (!this.isRangeValid()) return

      switch (action) {
        case this.sellCerts: {
          if (!this.backdating.canSell) {
            await this.sellCerts()
            return
          }

          this.modalBackdating.title = this.$t('button.sell')

          break
        }
        case this.activateCerts: {
          if (!this.backdating.canActivate) {
            await this.activateCerts()
            return
          }

          const max = Math.max(...this.selectedItems.map(v => new Date(v.soldAt)))
          this.modalBackdating.min = new Date(max)
          this.modalBackdating.title = this.$t('button.activate')

          break
        }
        default:
          return
      }

      this.modalBackdating.date = new Date()
      this.modalBackdating.action = action
      this.$set(this.modalBackdating, 'show', true)
    },
    resetModalBackdating() {
      this.modalBackdating.title = ''
      this.modalBackdating.min = undefined
      this.modalBackdating.date = null
      this.modalBackdating.action = () => {}
    },

    async sellCerts() {
      try {
        if (this.backdating.canSell) {
          this.modalBackdating.date = new Date(this.modalBackdating.date)
        }

        this.loading = true
        await this.$api.certs.sell(this.selectedRows, this.modalBackdating.date)

        this.forEachItem(v => {
          v.state = 'sold'
          v.soldBy = this.$store.state.user
          v.soldAt = this.modalBackdating.date || new Date()
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    async activateCerts() {
      try {
        if (this.backdating.canActivate) {
          this.modalBackdating.date = new Date(this.modalBackdating.date)
        }

        this.loading = true
        await this.$api.certs.activate(this.selectedRows, this.modalBackdating.date)

        this.forEachItem(v => {
          v.state = 'activated'
          v.activatedBy = this.$store.state.user
          v.activatedAt = this.modalBackdating.date || new Date()
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async readSecret(item) {
      if (item.secret) {
        return
      }

      const { data: { secret } } = await this.$api.certs.secret(item.id)
      this.$set(item, 'secret', secret)
      this.$set(item, 'secretTimer', '0:10')

      let seconds = 10
      const interval = setInterval(() => {
        seconds -= 1

        this.$set(
          item, 'secretTimer',
          `0:${seconds.toString().padStart(2, '0')}`
        )

        if (seconds === 0) {
          item.secret = undefined
          item.secretTimer = undefined
          clearInterval(interval)
        }
      }, 1000)
    },
    toggleEdit(item) { // goes before extendCertGroup
      item.expiresAt = new Date(item.expiresAt)
      this.$refs.table.toggleEdit(item)
    },
    async extendCert(item) {
      if (this.$refs.table.isItemCached(item)) {
        this.$refs.table.toggleEdit(item)
        return
      }

      try {
        this.loading = true
        item.expiresAt = new Date(item.expiresAt)
        await this.$api.certs.extend(item.id, item.expiresAt)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    banCert(item, ban) {
      this.$bvModal.msgBoxConfirm(this.$t(`modal.ban.message.${ban}`), {
        title: this.$t('modal.ban.title'),
        okTitle: this.$t(`modal.ban.ok.${ban}`),
        cancelTitle: this.$t('modal.ban.cancel'),
        okVariant: ban ? 'danger' : 'success',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          this.loading = true

          if (ban) {
            await this.$api.certs.ban(item.id)
            item.state = 'banned'
          } else {
            await this.$api.certs.instock([item.id])
            item.state = 'instock'
          }
        } catch (e) {
          this.error = true
        } finally {
          this.loading = false
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>

<i18n locale="ru" lang="yaml">
label:
  checked: Отмеченные
  not: Не
  from: от
  to: до
button:
  instock: На склад
  transfer: В работу
  sell: Продать
  activate: Активировать
  ban: Заблокировать
  unban: Разблокировать
  secret: Секретный код
  extend: Продлить
field:
  id: ID
  createdAt: Создан
  expiresAt: Дата истечения
  cost: Номинал, грн
  owner: Владелец
  soldBy: Продан
  activatedBy: Активирован
  state: Состояние
  actions: Действия
state:
  instock: На складе
  ready: Готов к активации
  sold: Продан
  activated: Активирован
  banned: Заблокирован
  transfers: Передается <b>{owner.fullName}</b> → <b>{transferTo.fullName}</b>
modal:
  transfer:
    message: |
      Выберите пользователя, которому хотите передать выбранную группу сертификатов.
      Пункт <b>Не передавать</b> запустит сертификаты в работу на текущем пользователе.
    title: Передача сертификатов {0}
    ok: Подтвердить
    cancel: Отмена
    defaultSelect: Не передавать
  backdating:
    message: |
      Укажите дату активации или продажи.
      По умолчанию установится текущее время.
    ok: Подтвердить
    cancel: Отмена
  ban:
    message:
      true: Вы уверены, что хотите заблокировать этот сертификат?
      false: Вы уверены, что хотите разблокировать этот сертификат?
    title: Подтверждение
    ok:
      true: ЗАБЛОКИРОВАТЬ
      false: Разблокировать
    cancel: Отмена
</i18n>
