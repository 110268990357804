<template>
  <div>
    <b-card class="sm-12">
      <q-table
        ref="table"
        :loading="loading"
        :error="error"
        :items="items"
        :fields="fields"
        :new-item="newItem"
      >
        <template slot="top-actions">
          <b-btn variant="success" @click="openModal">
            <font-awesome-icon icon="user-plus" />
            {{ $t('button.create') }}
          </b-btn>
        </template>

        <template v-slot:cell(avatar)="row">
          <img
            :src="$global.userAvatar(row.item)"
            class="img-circle w-50"
          >
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item._edit"
            :title="$t('button.edit')"
            :disabled="isDisabled(row.item) && row.item.id !== $store.state.user.id"
            variant="info"
            @click="$refs.table.toggleEdit(row.item)"
          >
            <font-awesome-icon icon="user-edit" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.confirm')"
            variant="success"
            @click="confirmEdit(row.item, row.index)"
          >
            <font-awesome-icon icon="user-check" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-if="!row.item.banned"
            :title="$t('button.ban')"
            :disabled="isDisabled(row.item)"
            variant="danger"
            @click="setUserBanned(row.item, true)"
          >
            <font-awesome-icon icon="user-slash" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            v-else
            :title="$t('button.unban')"
            :disabled="isDisabled(row.item)"
            variant="success"
            @click="setUserBanned(row.item, false)"
          >
            <font-awesome-icon icon="user-lock" />
          </b-btn>
          <b-btn
            v-b-tooltip.hover
            :disabled="isDisabled(row.item)"
            :title="$t('button.delete')"
            variant="danger"
            @click="deleteUser(row.item)"
          >
            <font-awesome-icon icon="user-times" />
          </b-btn>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-row>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.phone')">
                <span v-if="row.item.phone && !row.item._edit">
                  {{ row.item.phone }}
                </span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="6" >
                    <b-form-input
                      v-model="row.item.phone"
                      class="text-center"
                      size="sm"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.rentalPoints')">
                <template v-if="!row.item._edit" >
                  <span
                    v-for="rp in row.item.rentalPoints"
                    :key="rp.name"
                    style="white-space: pre;"
                  >{{ rp.name }}<br/></span>
                </template>
                <b-row v-else class="justify-content-center">
                  <b-col cols="6">
                    <b-form-select
                      v-model="row.item.rentalPoints[0]"
                      :options="rentalPointsOptions"
                      size="sm"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </a-lte-description-block>
            </b-col>
            <b-col sm4>
              <a-lte-description-block :header="$t('header.note')">
                <span
                  v-if="row.item.note && !row.item._edit"
                  style="white-space: pre;"
                >{{ row.item.note }}</span>
                <b-row v-else-if="row.item._edit" class="justify-content-center">
                  <b-col cols="10">
                    <b-form-textarea
                      v-model="row.item.note"
                      :placeholder="$t('header.note')"
                      size="sm"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <i v-else>{{ $t('empty') }}</i>
              </a-lte-description-block>
            </b-col>
          </b-row>
        </template>
      </q-table>
    </b-card>

    <b-modal
      id="modal-password"
      ref="modalPassword"
      :title="$t('modal.password.title')"
      :ok-title="$t('modal.password.ok')"
      :cancel-title="$t('modal.password.cancel')"
      @ok="handleModalOk"
      @show="resetModal"
      @hidden="resetModal"
    >
      <template v-slot:default>
        <form @submit.stop.prevent="createUser">
          <b-form-group
            :state="modalState"
            :invalid-feedback="$t(`modal.password.invalid.${modalInvalidFeedbackKey}`)"
            :valid-feedback="$t('modal.password.valid')"
          >
            <b-input-group>
              <b-form-input
                v-model="modal.password"
                :state="modalState"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="success"
                  @click="generateModalPassword"
                >
                  {{ $t('modal.password.button') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import QTable from '@/components/table/QTable.vue'

export default {
  components: {
    QTable
  },
  data() {
    return {
      loading: true,
      error: false,
      items: [],
      newItem: {},

      modal: { password: '' },

      groups: [],
      rentalPoints: [],

      fields: [
        {
          key: 'createdAt',
          label: this.$t('field.createdAt'),
          sortable: true,
          formatter: v => new Date(v).toLocaleString()
        },
        {
          key: 'avatar',
          label: this.$t('field.avatar'),
          class: 'avatar-field'
        },
        {
          key: 'email',
          label: this.$t('field.email'),
          sortable: true,
          editable: true,
          validator: v => !!v.match(/^[^@\s]+@[^@\s.]+\.[^@.\s]+$/)
        },
        {
          key: 'fullName',
          label: this.$t('field.fullName'),
          sortable: true,
          editable: true,
          validator: v => !!v
        },
        {
          key: 'group',
          label: this.$t('field.group'),
          sortable: true,
          editable: true,
          selectable: true,

          selectOptions: () => this.groups.map(g => ({
            value: g,
            text: g.name,
            disabled: g.id === 1 // always super-admin
          })),

          validator: v => !!v,
          formatter: v => v.name,
          disabled: v => v.group.id === 1
        },
        {
          key: 'actions',
          label: this.$t('field.actions')
        }
      ]
    }
  },
  computed: {
    rentalPointsOptions() {
      return [
        {
          value: null,
          text: '',
          disabled: true
        },
        ...this.rentalPoints.map(rp => ({
          value: rp,
          text: rp.name
        }))
      ]
    },
    modalState() {
      return !!this.modal.password.match(/^[A-Za-z0-9]\w{7,}$/)
    },
    modalInvalidFeedbackKey() {
      if (!this.modal.password.match(/^[A-Za-z0-9]\w{7,}$/)) {
        return 'bad'
      }
      if (this.modal.password.length > 0) {
        return 'length'
      }
      return ''
    }
  },
  async mounted() {
    try {
      const { data: usersData } = await this.$api.users.list()
      for (let i = 0; i < usersData.users.length; i++) {
        if (!usersData.users[i].rentalPoints) {
          usersData.users[i].rentalPoints = [{}]
        }
      }

      const { data: groupsData } = await this.$api.groups.list()
      this.groups = groupsData.groups

      this.groups.forEach(g => delete g.createdAt)
      this.groups.forEach(g => delete g.updatedAt)
      this.groups.forEach(g => delete g.permissions)

      const { data: rpData } = await this.$api.rentalPoints.list()
      this.rentalPoints = rpData.rentalPoints

      this.items = usersData.users
      this.$refs.table.refresh()
      this.loading = false
    } catch (e) {
      this.error = true
    }

    this.newItem = this.defaultNewItem()
    setInterval(() => {
      this.newItem.createdAt = new Date()
    }, 1000)
  },
  methods: {
    defaultNewItem() {
      return {
        createdAt: new Date(),
        email: '',
        rentalPoints: [this.rentalPointsOptions[0]]
      }
    },
    isDisabled(item) {
      return item.group.id <= this.$store.state.user.group.id
    },

    async createUser() {
      try {
        this.loading = true
        this.newItem.password = this.modal.password
        const { data } = await this.$api.users.create(this.newItem)

        const item = Object.assign({}, this.newItem)
        item.id = data.id
        item._edit = true
        item._showDetails = true

        this.items.push(item)
        this.newItem = this.defaultNewItem()

        this.$global.toast('user.created', 'successInfo')
        this.$refs.table.sort('createdAt', true)
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.refresh()
      }
    },
    async confirmEdit(item, index) {
      if (!this.$refs.table.isFieldsValid(item)) {
        this.$global.toast('dataInvalid')
        this.$refs.table.restoreItem(index)
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      if (this.$refs.table.isItemCached(item)) {
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
        return
      }

      try {
        this.loading = true
        await this.$api.users.update(item)
        this.$global.toast('user.updated', 'successInfo')
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
        this.$refs.table.toggleEdit(item)
        this.$refs.table.refresh()
      }
    },
    setUserBanned(item, ban) {
      this.$bvModal.msgBoxConfirm(this.$t(`modal.ban.message.${ban}`), {
        title: this.$t('modal.ban.title'),
        okTitle: this.$t(`modal.ban.ok.${ban}`),
        cancelTitle: this.$t('modal.ban.cancel'),
        okVariant: ban ? 'danger' : 'success',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          if (ban) {
            await this.$api.users.ban(item.id)
          } else {
            await this.$api.users.unban(item.id)
          }

          item.banned = ban
          this.$global.toast('user.updated', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    },
    deleteUser(item) {
      this.$bvModal.msgBoxConfirm(this.$t('modal.delete.message'), {
        title: this.$t('modal.delete.title'),
        okTitle: this.$t('modal.delete.ok'),
        cancelTitle: this.$t('modal.delete.cancel'),
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        try {
          await this.$api.users.delete(item.id)
          this.items = this.items.filter(x => x.id !== item.id)
          this.$global.toast('user.deleted', 'successInfo')
          this.$refs.table.refresh()
        } catch (e) {
          this.error = true
        }
      })
    },

    openModal() {
      if (!this.$refs.table.isFieldsValid(this.newItem)) {
        this.$global.toast('dataInvalid')
        return
      }
      this.$nextTick(() => { this.$refs.modalPassword.show() })
    },
    handleModalOk(modal) {
      modal.preventDefault()

      if (this.modalState) {
        this.createUser()
        this.$nextTick(() => { this.$refs.modalPassword.hide() })
      }
    },
    generateModalPassword() {
      this.modal.password = Math.random().toString(36).slice(-8)
    },
    resetModal() {
      this.modal.password = ''
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
button:
  create: Создать
  edit: Редактировать
  confirm: Подтвердить
  ban: Заблокировать
  unban: Разблокировать
  delete: Удалить
field:
  createdAt: Создан
  avatar: Фото
  email: Email
  fullName: ФИО
  group: Группа
  actions: Действия
header:
  phone: Телефон
  rentalPoints: Точки проката
  note: Заметка админа
modal:
  password:
    title: Пароль пользователя
    ok: Подтвердить
    cancel: Отмена
    valid: Пароль соответствует требованиям
    invalid:
      bad: Пароль не соответствует требованиям
      length: Минимум — 8 символов
    button: Сгенерировать
  ban:
    message:
      true: Вы уверены, что хотите заблокировать этого пользователя?
      false: Вы уверены, что хотите разблокировать этого пользователя?
    title: Подтверждение
    ok:
      true: ЗАБЛОКИРОВАТЬ
      false: Разблокировать
    cancel: Отмена
  delete:
    message: Вы уверены, что хотите удалить этого пользователя?
    title: Подтверждение
    ok: УДАЛИТЬ
    cancel: Отмена
empty: Пусто
</i18n>
