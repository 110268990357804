<template>
  <b-card class="sm-12">
    <FullCalendar ref="calendar" :options="calendar" />
    <b-modal
      id="modal-schedule-create"
      ref="modalSchedule"
      :ok-title="selectedEvent.id ? 'Сохранить изменения':'Добавить'"
      title="Добавить запись"
      cancel-title="Отменить"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template v-slot:default>
        <form @submit.stop.prevent="postScheduleEntry">
          <b-form-group
            v-for="f of fields"
            v-if="checkDependency(f) === true"
            :state="modalState"
            :invalid-feedback="'invalid feedback'"
            :valid-feedback="'valid'"
            :key="f.key"
          >
            <b-input-group>
              <template
                v-slot:prepend
                v-if="hasPrepend(f)"
              >
                <b-dropdown
                  v-if="f.dropdownOptions"
                  no-caret
                  style="width: 42px;"
                >
                  <template v-slot:button-content>
                    <font-awesome-icon v-if="f.dropdownValue" :icon="dropdownOption(f).icon"/>
                  </template>
                  <b-dropdown-item
                    v-for="d in f.dropdownOptions"
                    :key="d.value"
                    @click="f.dropdownValue = d.value; selectedEvent[f.dropdownKey] = d.value"
                  >
                    <font-awesome-icon :icon="d.icon" class="mr-1"/>
                    {{ d.text }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-else-if="f.button"
                  :id="f.key"
                  @click="buttonClick(f.key)"
                >
                  <font-awesome-icon :icon="f.button"/>
                </b-button>
                <b-input-group-text
                  v-else
                  style="width: 42px;"
                >
                  <font-awesome-icon
                    v-if="f.icon"
                    :icon="f.icon"
                  />
                  <b-form-checkbox
                    v-else-if="f.checkbox"
                    v-model="selectedEvent[f.checkbox]"
                    class="mr-n2"
                  />
                </b-input-group-text>
              </template>
              <b-form-tags
                v-if="f.selectOptions !== undefined && f.selectType === 'multiple'"
                id="tags-component-select"
                v-model="selectedEvent[f.key]"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <b-form-select
                    v-bind="inputAttrs"
                    :disabled="disabled"
                    :options="f.selectOptions()"
                    v-on="inputHandlers"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">{{ f.label }}</option>
                    </template>
                  </b-form-select>
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-2 mb-0">
                    <li
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                        @remove="removeTag(tag)"
                      >
                        {{ findTranslation(f, tag) }}
                      </b-form-tag>
                    </li>
                  </ul>
                </template>
              </b-form-tags>
              <b-form-select
                v-else-if="f.selectOptions !== undefined && f.selectType !== 'multiple'"
                v-model="selectedEvent[f.key]"
                :options="f.selectOptions()"
              >
              </b-form-select>
              <b-form-input
                v-else-if="[String, Number].includes(f.type) && !f.textarea && !f.file && !f.selectOptions"
                v-model="selectedEvent[f.key]"
                :type="f.type === Number ? 'number' : 'text'"
                :required="f.required"
                :disabled="f.checkbox && !selectedEvent[f.checkbox]"
                :value="selectedEvent[f.key] ? selectedEvent[f.key] : ''"
                :placeholder="f.label"
              />
              <b-form-textarea
                v-else-if="f.textarea"
                id="textarea-small"
                v-model="selectedEvent[f.key]"
                :value="selectedEvent[f.key]"
                :placeholder="f.label"
                size="md"
              />
              <div
                v-else-if="f.file"
                class="position-relative"
              >

                <b-button
                  :pill="true"
                  :class="!selectedEvent[f.key] ? ['position-absolute', 'mt-3', 'ml-2'] : undefined"
                  :size="!selectedEvent[f.key] ? 'xs' : 'sm'"
                  @click="$refs[`file-${selectedEvent.id}`].$el.querySelector(`input[type=file]`).click()"
                >
                  <font-awesome-icon v-if="!selectedEvent[f.key]" icon="camera" />
                  <font-awesome-icon icon="plus" size="xs" />
                </b-button>

              </div>
              <template v-slot:append v-if="f.append">
                <b-input-group-append>
                  <b-input-group-text>{{ f.append }}</b-input-group-text>
                </b-input-group-append>
              </template>
            </b-input-group>
          </b-form-group>
          <b-button
            v-if="selectedEvent.id"
            variant="outline-danger"
            block
            @click="deleteEntry(selectedEvent)"
          >
            Удалить
          </b-button>
          <viewer
            v-if="selectedEvent.prepayPhoto"
            :trigger="selectedEvent.photo || selectedEvent.prepayPhoto"
            rebuild
            class="collapse"
            @inited="setViewer"
          >
            <img
              v-b-tooltip.hover
              :src="$global.storagePath('schedule', selectedEvent.id, selectedEvent.prepayPhoto)"
              class="photo-field position-relative"
            />
          </viewer>
          <b-file
            id="photo_file"
            :ref="`file-${selectedEvent.id}`"
            v-model="selectedEvent.photo"
            class="collapse"
            style="width: 0;"
            accept=".png, .jpg"
            @input="file => uploadPhoto(selectedEvent.id, file)"
          />
        </form>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru'

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      calendar: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        locale: ruLocale,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false
        },
        headerToolbar: {
          left: 'create,notify',
          center: 'title',
          right: 'month,week,day prev,next'
        },
        buttonText: {
          today: 'cегодня'
        },
        themeSystem: 'bootstrap',
        customButtons: {
          create: {
            text: 'добавить',
            click: this.createEntry
          },
          notify: {
            text: 'оповестить',
            click: this.notify
          },
          month: {
            text: 'месяц',
            click: () => { this.$refs.calendar.getApi().changeView('dayGridMonth') }
          },
          week: {
            text: 'неделя',
            click: () => { this.$refs.calendar.getApi().changeView('dayGridWeek') }
          },
          day: {
            text: 'день',
            click: () => { this.$refs.calendar.getApi().changeView('dayGridDay') }
          }
        },
        eventClick: this.handleEventSelect,
        selectable: true,
        select: this.handleDateSelect,
        unselect: () => { this.selectedCell = null },
        unselectAuto: false,
        selectConstraint: {
          startTime: '00:00',
          endTime: '24:00'
        },
        eventConstraint: {
          startTime: '00:00',
          endTime: '24:00'
        },

        events: []
      },

      modalState: null,
      selectedCell: null,

      times: [],
      vehicles: [],
      services: [],

      scheduleEntries: [],
      selectedEvent: {},

      fields: [
        {
          key: 'customer',
          label: 'Клиент',
          icon: 'user'
        },
        {
          key: 'contact',
          label: 'Контакт',

          dropdownKey: 'contactType',
          dropdownValue: 'phone',
          dropdownOptions: [
            { value: 'phone', text: 'Мобильный телефон', icon: 'mobile-alt' },
            { value: 'telegram', text: 'Telegram', icon: ['fab', 'telegram-plane'] },
            { value: 'viber', text: 'Viber', icon: ['fab', 'viber'] },
            { value: 'whatsapp', text: 'WhatsApp', icon: ['fab', 'whatsapp'] },
            { value: 'instagram', text: 'Instagram', icon: ['fab', 'instagram'] }
          ]
        },
        {
          key: 'timeId',
          label: 'Время',
          icon: 'clock',

          selectOptions: () => [
            { value: null, text: 'Выберите время' },
            ...this.times.map(t => ({
              value: t.id,
              text: `${String(t.hour).padStart(2, '0')}:${String(t.minute).padStart(2, '0')}`
            }))
          ]
        },
        {
          key: 'prepayAmount',
          label: 'Предоплата',
          checkbox: 'prepay',
          append: 'грн',
          validator: v => v > 0,
          type: Number
        },
        {
          key: 'prepayNote',
          depends: 'prepay',
          button: 'camera',
          label: 'Заметка к предоплате',
          textarea: true
        },
        {
          key: 'locationNote',
          label: 'Локация',
          icon: 'map-marker'
        },
        {
          key: 'passengersAdults',
          label: 'Взрослые',
          icon: 'male',
          type: Number,
          defaultValue: 0
        },
        {
          key: 'passengersKids',
          label: 'Дети',
          icon: 'child',
          type: Number,
          defaultValue: 0
        },
        {
          key: 'vehicles',
          label: 'Техника',

          selectType: 'multiple',
          selectOptions: () => this.vehicles.map(v => ({
            value: String(v.id),
            text: v.name
          }))
        },
        {
          key: 'services',
          label: 'Услуги',

          selectType: 'multiple',
          selectOptions: () => this.services.map(s => ({
            value: String(s.id),
            text: s.name
          }))
        },
        {
          key: 'warnings',
          label: 'Предупреждения',

          selectType: 'multiple',
          selectOptions: () => [
            { value: 'clothes', text: 'Сменная одежда' },
            { value: 'safety', text: 'Техника безопасности' }
          ]
        },
        {
          key: 'note',
          label: 'Заметка',
          textarea: true
        }
      ]
    }
  },
  async mounted() {
    this.fields.forEach(f => { if (!f.type) { f.type = String } })

    try {
      const { data: timesData } = await this.$api.times.list()
      const times = timesData.times || []

      const { data: vehiclesData } = await this.$api.vehicles.list()
      const vehicles = vehiclesData.vehicles || []

      const { data: servicesData } = await this.$api.services.list()
      const services = servicesData.services || []

      const { data: scheduleData } = await this.$api.schedule.list()
      this.scheduleEntries = scheduleData.entries || []
      this.scheduleEntries.forEach(entry => {
        const time = times.find(t => t.id === entry.timeId)
        if (!time) {
          console.log(`time with ${entry.timeId} does not exist, skipping`)
          return
        }

        const entryDate = new Date(entry.date)
        const startDate = new Date(entryDate.getTime()).setHours(time.hour, time.minute)
        const endDate = new Date(entryDate.getTime()).setHours(time.hour)

        const calendarEntry = {
          id: entry.id,
          title: entry.customer,
          start: startDate,
          end: endDate,
          date: entryDate,
          eventBackgroundColor: 'red'
        }

        this.calendar.events.push(calendarEntry)
      })

      this.times = times
      this.vehicles = vehicles
      this.services = services
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    buttonClick(key) {
      if (key === 'prepayNote') {
        if (this.selectedEvent.prepayPhoto) {
          this.$viewer.show()
          return
        }
        document.getElementById('photo_file').click()
      }
    },
    checkDependency(f) {
      if (f.depends) {
        return !!this.selectedEvent[f.depends]
      }
      return true
    },
    dropdownOption(f) {
      return f.dropdownOptions.filter(o => o.value === (this.selectedEvent[f.dropdownKey] || f.dropdownValue))[0]
    },
    hasPrepend(f) {
      return f.icon || f.dropdownOptions || f.checkbox || f.button
    },
    resetModal() {
      this.modalState = null
    },
    findTranslation(f, tag) {
      const opt = f.selectOptions().find(o => o.value === tag)
      return opt ? opt.text : ''
    },
    normalizeEvent() {
      this.selectedEvent.vehicles = this.selectedEvent.vehicles.map(v => parseInt(v, 10))
      this.selectedEvent.services = this.selectedEvent.services.map(s => parseInt(s, 10))
      this.selectedEvent.passengersAdults = parseInt(this.selectedEvent.passengersAdults, 10)
      this.selectedEvent.passengersKids = parseInt(this.selectedEvent.passengersKids, 10)
      this.selectedEvent.prepayAmount = parseInt(this.selectedEvent.prepayAmount, 10)
    },

    setViewer(viewer) {
      this.$viewer = viewer
      this.$viewer.reset = this.deletePhoto
    },
    async uploadPhoto(itemID, file) {
      if (!itemID || !file) return

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert(this.$t('error.invalidType')) // eslint-disable-line
        return
      }
      if (file.size >= this.$global.maxImageSize) {
        alert(this.$t('error.tooBig')) // eslint-disable-line
        return
      }

      const data = new FormData()
      data.append('file', file)
      const r = await this.$api.schedule.uploadPhoto(itemID, data)
      const { hashext } = r.data
      this.selectedEvent.prepayPhoto = hashext
    },
    async deletePhoto() {
      try {
        const photo = this.selectedEvent.prepayPhoto
        await this.$api.stocks.deleteInventoryPhoto(this.selectedEvent.id, photo)
        this.selectedEvent.prepayPhoto = undefined
      } catch (e) {
        // eslint-disable-prev-line no-empty
      }
    },

    async notify() {
      if (!this.selectedCell) {
        alert('Необходимо выбрать день в календаре!')
        return
      }

      await this.$api.bot.sendSchedule(this.selectedCell.start.toISOString())
      // TODO: handle error
    },
    createEntry() {
      if (!this.selectedCell) {
        alert('Необходимо выбрать день в календаре!')
        return
      }

      this.selectedCell.start.setHours(0)

      this.selectedEvent = {
        timeId: null,
        contactType: null,
        services: [],
        vehicles: [],
        warnings: [],
        date: this.selectedCell.start
      }

      this.$bvModal.show('modal-schedule-create')
    },
    async deleteEntry(entry) {
      await this.$api.schedule.delete(entry.id)
      this.scheduleEntries = this.scheduleEntries.filter(e => e.id !== entry.id)
      this.calendar.events = this.calendar.events.filter(e => e.id.toString() !== entry.id.toString())
      this.$bvModal.hide('modal-schedule-create')
    },

    async handleOk() {
      try {
        await this.handleSubmit()
      } catch (e) {
        console.log(e)
      }
    },
    async handleSubmit() {
      if (this.selectedEvent.id) {
        this.normalizeEvent()
        await this.$api.schedule.update(this.selectedEvent)

        this.scheduleEntries = this.scheduleEntries.filter(e => e.id !== this.selectedEvent.id)
        this.scheduleEntries.push(this.selectedEvent)
        this.calendar.events.forEach((v) => {
          if (v.id === this.selectedEvent.id) {
            v.title = this.selectedEvent.customer
          }
        })

        return
      }

      const time = this.times.find(t => t.id === this.selectedEvent.timeId)
      if (time) {
        const selectedDate = this.selectedCell.start
        selectedDate.setHours(time.hour, time.minute)
        this.selectedEvent.date = selectedDate
      }

      this.normalizeEvent()
      const { id } = await this.$api.schedule.create(this.selectedEvent)
      this.selectedEvent.id = id

      if (this.selectedEvent.photo) {
        await this.uploadPhoto(id, this.selectedEvent.photo)
      }

      this.calendar.events.push({ ...this.selectedEvent, title: this.selectedEvent.customer })
      this.scheduleEntries.push(this.selectedEvent)
    },

    handleDateSelect(selectInfo) {
      this.selectedCell = selectInfo
      this.selectedCell.start.setHours(0)
    },
    handleEventSelect(selectInfo) {
      this.selectedEvent = this.scheduleEntries.find(e => e.id === parseInt(selectInfo.event.id, 10))
      console.log(this.selectedEvent.contactType)
      this.$bvModal.show('modal-schedule-create')
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
error:
  notFound: Произошла ошибка при отправке.
</i18n>
