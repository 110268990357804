<template>
  <a-lte-layout-login>
    <router-link slot="header" to="/">
      <strong>LLC QuadroClub</strong> Ukraine
    </router-link>

    <p v-if="errorCode" class="text-center text-danger">
      <b>{{ $te(`error.${errorCode}`)
        ? $t(`error.${errorCode}`)
        : $t('error.default')
      }}</b>
    </p>
    <p v-else class="text-center">{{ $t('welcome') }}</p>
    <b-form class="mb-2" @submit.prevent="submit">
      <b-form-group>
        <b-input-group>
          <b-input-group-text slot="prepend">
            <font-awesome-icon icon="envelope" />
          </b-input-group-text>
          <b-form-input
            v-model="form.email"
            name="email"
            type="email"
            placeholder="Email"
            required
          />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <b-input-group>
          <b-input-group-text slot="prepend">
            <font-awesome-icon icon="key" />
          </b-input-group-text>
          <b-form-input
            v-model="form.password"
            name="password"
            type="password"
            placeholder="Пароль"
            required
          />
        </b-input-group>
        <router-link slot="description" to="">{{ $t('forgotPassword') }}</router-link>
      </b-form-group>
      <b-row>
        <!--
          <b-col cols="8">
            <b-form-checkbox v-model="form.rememberMe">
              Запомнить меня
            </b-form-checkbox>
          </b-col>
        -->
        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            block
          >
            {{ $t('enter') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </a-lte-layout-login>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: false
      },
      errorCode: 0
    }
  },
  methods: {
    async submit() {
      try {
        const { data: loginData } = await this.$api.login(this.form.email, this.form.password)
        if (loginData.token) {
          this.$store.commit('setToken', loginData.token)
        }

        const { data: userData } = await this.$api.users.me()
        if (userData.user) {
          this.$store.commit('setUser', userData.user)
        }

        this.$router.push({
          path: this.$route.query.redirect || 'me',
          query: { from: 'login' }
        })
      } catch (e) {
        if (!e.response) {
          this.errorCode = 'connection'
        } else {
          console.log(e.response)
          this.errorCode = e.response.status
        }
      }
    }
  }
}
</script>

<i18n locale="ru" lang="yaml">
welcome: Войдите, чтобы начать работу
forgotPassword: Забыли пароль?
enter: Войти

error:
  connection: Нет соединения с сервером!
  default: Неизвестная ошибка!
  400: Неверный логин или пароль!
  403: Доступ запрещен!
  404: Такого пользователя не существует!
</i18n>
