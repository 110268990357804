import 'es6-promise/auto'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueAdminLte from 'AdminLTE'
import VClickOutside from 'v-click-outside'
import VViewer from 'v-viewer'

import '@/plugins/icons'
import App from '@/App.vue'

import i18n from '@/plugins/i18n'
import api from '@/plugins/api'
import global from '@/plugins/global'

import { sync } from 'vuex-router-sync'
import store from '@/store'
import router from '@/router'


VViewer.setDefaults({
  title: false,
  movable: false,
  zoomable: false,
  rotatable: false,
  scalable: false,
  transition: false,
  fullscreen: false,
  button: false,
  toolbar: {
    prev: 'large',
    next: 'large',
    reset: 'large'
  }
})

Vue.use(BootstrapVue)
Vue.use(VueAdminLte)
Vue.use(VClickOutside)
Vue.use(VViewer)

Vue.use(api, store)
Vue.use(global, store, router)

sync(store, router)

Vue.prototype.$eventBus = new Vue()

new Vue({ // eslint-disable-line no-new
  el: '#app',
  router,
  store,
  i18n,
  render: createElement => createElement(App)
})
