<template>
  <a-lte-header border-bottom>
    <b-navbar
      type="light"
      variant="white"
      class="navbar-expand"
    >
      <b-navbar-nav>
        <b-nav-item v-a-lte-aside-toggle>
          <font-awesome-icon icon="bars" />
        </b-nav-item>
        <!-- <b-nav-item to="/" class="d-none d-sm-inline-block"/> -->
      </b-navbar-nav>

      <b-nav-form v-if="$route.meta.searchable" class="ml-3">
        <b-input-group size="sm">
          <b-form-input
            v-model="search"
            class="form-control-navbar"
            placeholder="Поиск..."
          />
          <b-input-group-append>
            <b-button variant="navbar" @click="onSearch">
              <font-awesome-icon icon="search" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-nav-form>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown no-caret right>
          <template slot="button-content">
            <font-awesome-icon icon="bell" />
            <b-badge
              v-if="notifs.length"
              variant="warning"
              class="navbar-badge"
            >{{ notifs.length }}</b-badge>
          </template>
          <template v-if="notifs.length">
            <b-dropdown-item
              v-for="notif in notifs"
              :key="notif.id"
              @click="onNotif(notif)"
            >
              <div v-if="notif.type === 'certsTransfer'" class="media">
                <img
                  :src="$global.userAvatar(notif.sender)"
                  class="img-size-50 mr-3 img-circle"
                  alt=""
                >
                <div class="media-body">
                  {{ notif.sender.fullName }}
                  <p class="text-sm mb-0">{{ $t(`notif.${notif.type}`) }}</p>
                  <p class="text-sm text-muted mb-0">
                    <font-awesome-icon class="mr-1" icon="clock" />
                    {{ new Date(notif.createdAt).toLocaleDateString() }}
                  </p>
                </div>
              </div>
            </b-dropdown-item>
          </template>
          <b-dropdown-item v-else>
            <p class="text-sm mb-0">{{ $t('none') }}</p>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item @click="$global.auth.logout()">
          <font-awesome-icon icon="sign-out-alt" />
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </a-lte-header>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      notifs: []
    }
  },
  async mounted() {
    await this.updateNotifs()
    setInterval(this.updateNotifs, 30000)
  },
  methods: {
    onSearch() {
      this.$eventBus.$emit('search', this.search)
    },

    async updateNotifs() {
      const { data: { notifs } } = await this.$api.notifs.list()
      this.notifs = notifs || []
    },
    async onNotif(notif) {
      switch (notif.type) {
        case 'certsTransfer':
          await this.transferCerts(notif)
          break
        default:
          break
      }
    },

    transferCerts(notif) {
      notif.range = this.$global.rangeString(notif.data)

      this.$bvModal.msgBoxConfirm(this.$t('modal.certsTransfer.message'), {
        title: this.$i18n.tf('modal.certsTransfer.title', notif),
        okTitle: this.$t('modal.certsTransfer.ok'),
        cancelTitle: this.$t('modal.certsTransfer.cancel'),
        okVariant: 'success',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (!value) return

        await this.$api.certs.transfer(notif.data, this.$store.state.user.id, notif.id)
        await this.updateNotifs()

        this.$global.toast('certs.transfer', 'successInfo')
        this.$router.push('/certs/view')
      })
    }
  }
}
</script>

<i18n lang="yaml" locale="ru">
none: Оповещений нет
notif:
  certsTransfer: Запрос на передачу сертификатов
modal:
  certsTransfer:
    message: |
      Вы станете новым владельцем этих сертификатов после подтверждения.
    title: Передача сертификатов {range} от {sender.fullName}
    ok: Принять
    cancel: Отмена
</i18n>
