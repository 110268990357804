var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "header",
    _vm._g(
      _vm._b(
        {
          class: {
            "main-header": true,
            "border-bottom": _vm.props.borderBottom
          }
        },
        "header",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }